<template>
  <div class="graphright" style="position: relative;">
    <div class="type-group" style="position: absolute;top: 31px;z-index: 999;">
      <!-- <span
        class="pick-type"
        v-for="(item, idx) in timetType"
        :key="idx"
        @click="changeType(item.code)"
      >
        <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
          item.name
        }}</font>
      </span> -->
    </div>
    <siteName :objname="objname"></siteName>
    <div ref="graphright" id="EnergyRanking"></div>
  </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
import siteName from "../../financeView/site";
export default {
  name: 'graphright',
  props: {},
  components: { siteName },
  data() {
    return {
      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
        
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      searchObj: {
        TYPE: "按年", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      detailInfo: {
        chart1: {},
      },
      objname: {
        name: 'Energy Ranking By Region',
        site: '区域能源排名'
      },
      newarr1:[]
    }
  },
  watch: {
    "currentTime": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    "currenttype": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.searchObj.TYPE = newVal
          
          if(newVal == "按天"){
            this.searchObj.TYPE = "按月"
          }
          this.getData();
          
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    currentTime() {
      return this.$store.state.timer;

    },
    currenttype(){
      return this.$store.state.financetype;

    },
    currentSelectDeptInfo() {
      return this.$store.state.overviewdept;
    },
  },
  methods: {
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day15m",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    getData() {
      // var deptCode = 'JG221027051'
      const url = Config.dev_url + "/api-apps-v2/api/v2/electric/subterm/kpi_ranking_company";
      var data = {
        code: this.$store.state.overviewdept,
        //  code:"221027091",
      };
      Object.assign(data, this.transformSearch());
      this.isLoading = true;
      this.$axios
        .post(url, data)
        .then((res) => {
          //console.log(res.data.data);
          this.detailInfo = {};
          var chart1 = {
            categoryData: res.data.data.categoryData.reverse(),
            seriesData: [
              {
                name: "实际",
                value: res.data.data.seriesData.realEnergy.reverse(),
              }
            ],
            top: res.data.data.seriesData.ranking.reverse(),
            freeSpace: res.data.data.seriesData.freeSpace.reverse(),
          };
          this.detailInfo.chart1 = chart1;
          this.initEchart();
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    
    initEchart() {
     
      var dom = document.getElementById('EnergyRanking');
      let chartInit = echarts.init(dom);
      var { categoryData, seriesData } = this.detailInfo.chart1;
      var series = [];
      console.log(seriesData)
      seriesData.forEach((item) => {
        var obj = {
          type: "bar",
          animationDurationUpdate: 2000,
          animationEasingUpdate: "quinticInOut",
          name: item.name,
          data: item.value,
          smooth: false,
          label: {
            show: true,
            position: 'right',
            fontSize: 12,
            color:"white"
          },
          itemStyle: {
                normal: {
                    
                    color: new echarts.graphic.LinearGradient(
                        1, 0, 0, 0, [{
                                offset: 0,
                                color: 'rgba(79,172,254,1)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(0,135,254,0)'
                            }
                        ]
                    )
                }
            },
          emphasis: {
            focus: "series",
          },
          // barGap:0,
          barWidth: 10,
        };
        series.push(obj);
      });
      var option = {
        animation: true,
        layoutAnimation: false,

        grid: {
          left: "0%",
          right: "15%",
          bottom: "7%",
          top: "0%",
          containLabel: true,
        },
        legend: {
          show:false,
          right: 0,
          top: "20%",
          icon: "rect",
          itemHeight: 8,
          itemWidth: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          // formatter: function (params) {
          //   let str = "";
          //   str += params[0].axisValueLabel + "<br>";
          //   str +=
          //     "<div style='width: 100%;display: flex;justify-content: space-between;align-items: center;margin-top: 10px'>" +
          //     "排名" +
          //     "<span style='margin-right: 10px'></span>" +
          //     vm.detailInfo.chart1.top[params[0].dataIndex] +
          //     "</div>";
          //   params.forEach((item, index) => {
          //     str +=
          //       "<div style='width: 100%;display: flex;justify-content: space-between;align-items: center'>" +
          //       "<span>" +
          //       item.marker +
          //       item.seriesName +
          //       "</span>" +
          //       "<span style='margin-right: 10px'></span>" +
          //       item.value +
          //       "</div>";
          //   });
          //   return str;
          // },
        },
        xAxis: {
          type: "value",
          name: "(kWh)",
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
            fontSize: 14,
          },
          axisLine: {
            show:false,
            lineStyle: {
              color: "white",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
          },
          splitLine: {
                show: false,
                lineStyle: {
                    color: '#192a44'
                },
          },
          // 控制数据参数最大值和最小值
          // interval: 50,
          // max: 100
        },
        yAxis: {
          type: "category",
          name: "",
          data: categoryData,
          boundaryGap: true,
          axisLine: {
            show:false,
            lineStyle: {
              color: "white",
            },
          },
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
            fontSize: 14,
            padding:[0,0,0,30]
          },
          splitLine: {
                show: false,
                lineStyle: {
                    color: '#192a44'
                },
            },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
          },
        },
        // 控住柱状图样式
        series: series,
      };
      setTimeout(() => { chartInit.resize() }, 200)
      
      chartInit.setOption(option);
      window.addEventListener("resize", function () {
        chartInit.resize()
      });
    }
  },
  mounted() {
    this.getData()
    // this.initEchart()
  }
}
</script>
                
<style lang="less" scoped>
.pick-type font {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color:rgba(255,255,255,0.7);
  line-height: 18px;
}
.type-group .pick-type .isactive-type{
  color:#DEFFFF;
}
.graphright {
  width: 100%;
  height: 100%;
  ul>li {
    width: 281px;
    height: 85px;
    display: flex;
    flex-direction: column;
    background-image: url(../../../../assets/icon/icon/lang01.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

#EnergyRanking {
  width: 100%;
  height: calc(100% - 38px);
}
</style>
                