<template>
  <div class="listTable">
    <div class="grouptitle">{{ objSite.nameSite }} <span>{{ objSite.name }}</span></div>
    <img src="@/assets/imgs/big/xian.svg" style="width: 100%;
      height: 16px; height: 16px;" />
    <div>
      <div class="tablelist">
        <div style="text-align: center;width: 100px;">设备</div>
        <div style="text-align: center;width: 100px;" v-if="objSite.site == 'left'">描述</div>
        <div style="text-align: center;width: 100px;" v-else>变量</div>
        <div style="width: 70px;text-align: center;" class="topstatus">状态</div>
        <div style="width: 170px;text-align: center;" v-if="objSite.site == 'left'">开始时间</div>
        <div style="width: 170px;text-align: center;" v-else>发生时间</div>
      </div>
      <div>
        <div v-for="(item, index) in ziData" :key="index" :style="{ 'background': index % 2 == 0 ? '#1c2737' : '' }"
          class="list">
          <div v-if="objSite.site == 'left'">
            <el-tooltip class="item" effect="dark" :content="item.SBMS" placement="top">
              <p>{{ item.SBMS }}</p>
            </el-tooltip>
          </div>
          <div v-else>
            <el-tooltip class="item" effect="dark" :content="item.ASSETNAME" placement="top">
              <p>{{ item.ASSETNAME }}</p>
            </el-tooltip>
          </div>
          <div v-if="objSite.site == 'left'">
            <el-tooltip class="item" effect="dark" :content="item.DESCRIPTION" placement="top">
              <p>{{ item.DESCRIPTION }}</p>
            </el-tooltip>
          </div>
          <div v-else>
            <el-tooltip class="item" effect="dark" :content="item.VARIABLEREALNAME" placement="top">
              <p style="text-align: center;">{{ item.VARIABLEREALNAME }}</p>
            </el-tooltip>
          </div>
          <div v-if="objSite.site == 'left'" style="width: 70px;" class="status">
            <el-tooltip class="item" effect="dark" :content="item.STATUS" placement="top" style="width: 70px;    text-align: center;">
              <p :class="{'green':item.STATUS =='已关闭','red':item.STATUS =='进行中' }">{{ item.STATUS }}</p>
            </el-tooltip>
          </div>
          <div v-else style="width: 70px;" class="status">
            <el-tooltip class="item" effect="dark" :content="item.ALERTSTATUS" placement="top" style="width: 70px;    text-align: center;">
              <p :class="{'green':item.ALERTSTATUS =='已关闭','red':item.ALERTSTATUS =='进行中' }">{{ item.ALERTSTATUS }}</p>
            </el-tooltip>
          </div>
          <div style="width: 170px;">
            <el-tooltip class="item" effect="dark" :content="item.CREATETIME" placement="top" style="width: 170px;">
              <p>{{ item.CREATETIME }}</p>
            </el-tooltip>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
  
<script>

export default {
  name: "listTable",
  components: {},
  props: {
    objSite: {
      type: Object,
      default: function () {
        return {
          name: '',
          nameSite: '',
          url: '',
          site: ''
        };
      },
    },
  },
  watch: {
    "bigdeptcode": {
      handler: function () {
        this.getData();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      ziData: [],
      isLoading: false
    };
  },
  computed: {
    bigdeptcode() {
      return this.$store.state.bigdeptcode;
    },
  },
  methods: {
    getData() {
      let data = {}
      if (this.bigdeptcode == 221027388) {
        data = {
          limit: 5,
          p_order: "[]",
          p_query: "{\"links\":[],\"type\":\"LinkAnd\"}",
          page: 1
        };

      } else if (this.bigdeptcode == 221027423) {

        data = {
          limit: 5,
          p_order: "[]",
          p_query: "{\"links\":[{\"key\":\"FULLNAME\",\"links\":[],\"type\":\"Like\",\"value\":\"车间北配电房\"}],\"type\":\"LinkAnd\"}",
          page: 1
        };
        if (this.objSite.site == 'right') {
          data["p_query"] = "{\"links\":[{\"key\":\"STATIONNAME\",\"links\":[],\"type\":\"Like\",\"value\":\"车间北配电房\"}],\"type\":\"LinkAnd\"}"
        }

      } else if (this.bigdeptcode == 221027390) {

        data = {
          limit: 5,
          p_order: "[]",
          p_query: "{\"links\":[{\"key\":\"FULLNAME\",\"links\":[],\"type\":\"Like\",\"value\":\"能源楼配电房\"}],\"type\":\"LinkAnd\"}",
          page: 1
        };
        if (this.objSite.site == 'right') {
          data["p_query"] = "{\"links\":[{\"key\":\"STATIONNAME\",\"links\":[],\"type\":\"Like\",\"value\":\"能源楼配电房\"}],\"type\":\"LinkAnd\"}"
        }

      } else if (this.bigdeptcode == 221027422) {

        data = {
          limit: 5,
          p_order: "[]",
          p_query: "{\"links\":[{\"key\":\"FULLNAME\",\"links\":[],\"type\":\"Like\",\"value\":\"行政楼配电房\"}],\"type\":\"LinkAnd\"}",
          page: 1
        };

        if (this.objSite.site == 'right') {
          data["p_query"] = "{\"links\":[{\"key\":\"STATIONNAME\",\"links\":[],\"type\":\"Like\",\"value\":\"行政楼配电房\"}],\"type\":\"LinkAnd\"}"
        }
      }else{
        data = {
          limit: 5,
          p_order: "[]",
          p_query: "{\"links\":[],\"type\":\"LinkAnd\"}",
          page: 1
        };
      }
      this.isLoading = true;
      this.$axios({
        url: Config.dev_url + this.objSite.url,
        method: "post",
        data,
      })
        .then((res) => {
          let result = res.data.data;
          this.ziData = result
        })
        .catch(() => { })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
  },
};
</script>
  
<style scoped lang="less">
.red{
  color: #ffdb75;
}
.green{
  color: #32dd56;
}
.grouptitle {
  color: white;
  font-size: 0.2rem;

  &>span {
    color: white !important;
    font-size: 18px ;
    margin-right: 10px !important;
  }
}

.tablelist {
  display: flex;
  justify-content: space-around;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;

  &>div {
    color: white;
    font-size: 0.17rem;
  }
}

.listTable {
  .list {
    display: flex;
    justify-content: space-between;
    height: 36px;
    line-height: 36px;
    padding: 0 10px;

    &>div {
      color: white;
      white-space: nowrap;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
     
      text-overflow: ellipsis;

      &>p {
        font-size: 0.17rem;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>