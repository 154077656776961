<template>
  <div class="graphright" style="position: relative;">
    <div class="type-group" style="position: absolute;top: 31px;z-index: 999;">
      <!-- <span
        class="pick-type"
        v-for="(item, idx) in timetType"
        :key="idx"
        @click="changeType(item.code)"
      >
        <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
          item.name
        }}</font>
      </span> -->
    </div>
    <siteName :objname="objname"></siteName>
    <div ref="graphright" id="Energystructure"></div>
  </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
import siteName from "../../financeView/site";
export default {
  name: 'graphright',
  props: {},
  components: { siteName },
  data() {
    return {
      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
        
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      searchObj: {
        TYPE: "按年", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      detailInfo: {
        chart1: {},
      },
      objname: {
        name: 'Green Power Usage/Production',
        site: '绿电替代'
      },
      newarr1:[]
    }
  },
  watch: {
    "currentTime": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    "currenttype": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.searchObj.TYPE = newVal
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    currentTime() {
      return this.$store.state.timer;
    },
    currenttype(){
      return this.$store.state.financetype;
    },
    currentSelectDeptInfo() {
      return this.$store.state.overviewdept;
    },
  },
  methods: {
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day1h",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    getData() {
      let data = {
        deptCode: this.$store.state.overviewdept,
      };
      var url = Config.dev_url + "/api-apps-v2/api/v2/energy/renewable/replacement-ratio";
      // var url = Config.dev_url + "/api-apps/eskpi/green/energy/absorption";
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      
      this.$axios
        .post(url, data)
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.detailInfo = {};
            let newarr = [];
            // this.detailInfo.average = res.data.data.average.toString();
            // this.detailInfo.greenTotal = res.data.data.greenTotal.toString();
            // this.detailInfo.total = res.data.data.total.toString();
            this.detailInfo.average = res.data.data.totalRate.toString();
            this.detailInfo.greenTotal = res.data.data.totalGreenPower.toString();
            this.detailInfo.total = res.data.data.totalPower.toString();
            var chart1 = {
              categoryData: res.data.data.date,
              seriesData: res.data.data.seriesData,
            };
            this.detailInfo.chart1 = chart1;
          let arr = ['绿电替代率','绿电使用量','总用电量',];
          this.newarr1 = []
          for (const i in this.detailInfo) {
            if (this.detailInfo.hasOwnProperty.call(this.detailInfo, i)) {
              const element = this.detailInfo[i];
              console.log(element);
              if (typeof element == "string") {
                if (element >= 100000000000) {
                  this.newarr1.push({ value: element.substring(0, 5) / 10, unit: "亿kWh" });
                } else if (element >= 10000000000) {
                  this.newarr1.push({ value: element.substring(0, 4) / 10, unit: "亿kWh" });
                } else if (element >= 1000000000) {
                  this.newarr1.push({ value: element.substring(0, 3) / 10, unit: "亿kWh" });
                } else if (element >= 100000000) {
                  this.newarr1.push({ value: element.substring(0, 2) / 10, unit: "亿kWh" });
                } else if (element >= 10000000) {
                  this.newarr1.push({ value: element.substring(0, 4), unit: "万kWh" });
                } else if (element >= 1000000) {
                  this.newarr1.push({ value: element.substring(0, 3), unit: "万kWh" });
                } else if (element >= 100000) {
                  this.newarr1.push({ value: element.substring(0, 2), unit: "万kWh" });
                } else if (element >= 10000) {
                  this.newarr1.push({ value: element.substring(0, 2) / 10, unit: "万kWh" });
                } else if (element >= 1000) {
                  this.newarr1.push({ value: element, unit: "kWh" })
                } else if (element <= -100000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 7) / 10, unit: "亿kWh" });
                } else if (element <= -100000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 6) / 10, unit: "亿kWh" });
                } else if (element <= -10000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 5) / 10, unit: "亿kWh" });
                } else if (element <= -1000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 4) / 10, unit: "亿kWh" });
                } else if (element <= -100000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 3) / 10, unit: "亿kWh" });
                } else if (element <= -10000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 5), unit: "万kWh" });
                } else if (element <= -1000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 4), unit: "万kWh" });
                } else if (element <= -100000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 3), unit: "万kWh" });
                } else if (element <= -10000) {
                  this.newarr1.push({ value:"-" + element.substring(1,3) / 10, unit: "万kWh" });
                } else if (element <= -1000) {
                  this.newarr1.push({ value:element, unit: "kWh" });
                } else {
                  this.newarr1.push({ value:element, unit: "kWh" });
                }
              }
            }
          }
          this.newarr1.forEach((item,index)=>{
            item['name'] = arr[index]
          })
          this.newarr1.reverse()
            this.detailInfo.chart1.seriesData.forEach((item) => {
              item.value.forEach((val) => {
                newarr.push(val);
              });
            });
           
            this.initEchart();
          } else {
            // this.$message.warning(res.data.msg)
          }
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    initEchart() {
      console.log(this.detailInfo.chart1)
      var dom = document.getElementById('Energystructure');
      let chartInit = echarts.init(dom);
      var { categoryData, seriesData } = this.detailInfo.chart1;
      var option = {
        animation: true,
        layoutAnimation: false,
        grid: {
          left: '0%',
          right: "0%",
          bottom: "0%",
          top: "25%",
          containLabel: true,
        },
        legend: {
          right: 0,
          top: "0%",
          icon: "rect", //图例形状
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
       
        xAxis: {
          type: "category",
          data: categoryData,
          // boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "white",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
            fontSize: 14,
          },
        },
        yAxis: [
          {
            name: "电量（kWh）",
            axisTick: {
              show: false,
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
              padding:[0,0,0,20]
            },
            axisLine: {
              lineStyle: {
                color: "white",
              },
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
              formatter(v) {
                v = v.toString();
                if (v >= 100000000000) {
                  return v.substring(0, 5) / 10 + "亿";
                } else if (v >= 10000000000) {
                  return v.substring(0, 4) / 10 + "亿";
                } else if (v >= 1000000000) {
                  return v.substring(0, 3) / 10 + "亿";
                } else if (v >= 100000000) {
                  return v.substring(0, 2) / 10 + "亿";
                } else if (v >= 10000000) {
                  return v.substring(0, 4) + "万";
                } else if (v >= 1000000) {
                  return v.substring(0, 3) + "万";
                } else if (v >= 100000) {
                  return v.substring(0, 2) + "万";
                } else if (v >= 10000) {
                  return v.substring(0, 2) / 10 + "万";
                } else if (v >= 1000) {
                  return v;
                } else if (v <= -100000000000) {
                  return "-" + v.substring(1, 7) / 10 + "亿";
                } else if (v <= -100000000000) {
                  return "-" + v.substring(1, 6) / 10 + "亿";
                } else if (v <= -10000000000) {
                  return "-" + v.substring(1, 5) / 10 + "亿";
                } else if (v <= -1000000000) {
                  return "-" + v.substring(1, 4) / 10 + "亿";
                } else if (v <= -100000000) {
                  return "-" + v.substring(1, 3) / 10 + "亿";
                } else if (v <= -10000000) {
                  return "-" + v.substring(1, 5) + "万";
                } else if (v <= -1000000) {
                  return "-" + v.substring(1, 4) + "万";
                } else if (v <= -100000) {
                  return "-" + v.substring(1, 3) + "万";
                } else if (v <= -10000) {
                  return "-" + v.substring(1, 3) / 10 + "万";
                } else if (v <= -1000) {
                  return v;
                } else {
                  return v;
                }
              },
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: '#192a44'
                },
            },
            // 控制数据参数最大值和最小值
            // interval: 200,
            // max: 1000
          },
          {
            name: "(%)",
            axisTick: {
              show: false,
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
              padding:[0,0,0,25]
            },
            axisLine: {
              lineStyle: {
               color: "white",
              },
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
             color: "white",
              fontSize: 14,
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: '#192a44'
                },
            },
            // 控制数据参数最大值和最小值
            // interval: 400,
            max: 100,
          },
        ],

        // 控住柱状图样式
        // series:series,
        series: [
          {
            type: "bar",
            name: seriesData[0].name,
            data: seriesData[0].value,
            barGap: '-100%',
            itemStyle: {
            normal: {
                    barBorderRadius: 5,
                    color: new echarts.graphic.LinearGradient(
                        0, 0, 0, 1,
                        [
                            {offset: 0, color: 'rgba(79,172,254,0.9)'},

                            {offset: 1, color: 'rgba(79,172,254,0)'}
                        ]
                    )
                }
            },
            z: -12,
            animationDurationUpdate: 2000,
            animationEasingUpdate: "quinticInOut",
            smooth: false,
            showSymbol: false,
            barWidth: "10",
            emphasis: {
              focus: "series",
            },
            lineStyle: {
              // color: 'rgb(52, 102, 254)',
              width: 2,
            },
          },
          {
            type: "bar",
            name: seriesData[1].name,
            data: seriesData[1].value,
            itemStyle: {
            normal: {
                    
                    color: new echarts.graphic.LinearGradient(
                        0, 0, 0, 1,
                        [
                            {offset: 0, color: '#e8d65d'},

                            {offset: 1, color: '#e8d65d'}
                        ]
                    )
                }
            },
            animationDurationUpdate: 2000,
            animationEasingUpdate: "quinticInOut",
            // yAxisIndex: 1,
            smooth: false,
            showSymbol: false,
            barWidth: "10",
            emphasis: {
              focus: "series",
            },
            lineStyle: {
              // color: 'rgb(23, 167, 53)',
              width: 2,
            },
          },
          {
            type: "line",
            animationDurationUpdate: 2000,
            animationEasingUpdate: "quinticInOut",
            name: seriesData[2].name,
            data: seriesData[2].value,
            yAxisIndex: 1,
            smooth: false,
            emphasis: {
              focus: "series",
            },
            itemStyle: {
                color: 'rgba(105,252,189,1)'
              },
            lineStyle: {
              // color: 'rgb(104, 104, 104)',
              width: 2,
            },
          },
        ],
    
      };
      setTimeout(() => { chartInit.resize() }, 200)
      
      chartInit.setOption(option);
      window.addEventListener("resize", function () {
        chartInit.resize()
      });
    }
  },
  mounted() {
    this.getData()
    // this.initEchart()
  }
}
</script>
                
<style lang="less" scoped>
.pick-type font {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color:rgba(255,255,255,0.7);
  line-height: 18px;
}
.type-group .pick-type .isactive-type{
  color:#DEFFFF;
}
.graphright {
  width: 100%;
  height: 100%;
  ul>li {
    width: 281px;
    height: 85px;
    display: flex;
    flex-direction: column;
    background-image: url(../../../../assets/icon/icon/lang01.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

#Energystructure {
  width: 100%;
  height: calc(100% - 38px);
}
</style>
                