<template>
  <div class="graphleft">
    <siteName :objname="objname" style="z-index: 9999;"></siteName>
    <div ref="CarbonOverview" id="CarbonOverview"></div>
  </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
import siteName from "../../financeView/site";
export default {
  name: 'CarbonOverview',
  props: {},
  components: { siteName },
  data() {
    return {
      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
        
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      searchObj: {
        TYPE: "按年", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      detailInfo: {
        chart1: {},
      },
      objname: {
        name: 'Overview Of Carbon Emissions',
        site: '碳排放总览'
      },

    }
  },
  watch: {
    "currentTime": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.getData();
         
        }
      },
      deep: true,
      immediate: true,
    },
    "currenttype": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.searchObj.TYPE = newVal
          if(newVal == "按天"){
            this.searchObj.TYPE = "按月"
          }
          // if(newVal == "按天"){
          //   this.searchObj.TYPE = "按月"
          // }
          this.getData();
         
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
       
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    currentTime() {
      return this.$store.state.timer;
    },
    currenttype(){
      return this.$store.state.financetype;
    },
    currentSelectDeptInfo() {
      return this.$store.state.overviewdept;
    },
  },
  methods: {
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          queryType: "day15m",
          starttime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endtime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          queryType: "month",
          starttime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endtime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          queryType: "year",
          starttime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endtime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    getData() {
      this.isLoading = true;
      
      let url =
          Config.dev_url +
          "/api-apps-v2/api/v2/carbon_emissions/overview";
      let data = {
        code: this.$store.state.overviewdept,
        // code: "221027091",
        // starttime: "2023-01-01 00:00:00",
        // endtime: "2023-12-31 00:00:00"
      };
      if (this.searchObj.TYPE == "按季") {
        Object.assign(data, this.getTimes(this.value));
      } else {
        Object.assign(data, this.transformSearch());
      }
      this.$axios.post(url, data).then((res) => {
        this.list = res.data.data;
        // //console.log(res.data.data);
        var chart1 = { seriesData: [] };
        // let list1 = []
        // list1 = res.data.data[0]
        // list1.push({title: {offsetCenter: ['0%', '30%']}})
        // //console.log(list1)
        this.detailInfo.chart2 = res.data.data;
        // chart1.seriesData = res.data.data.splice(0, 1);
        if (res.data.data[0].value == null) {
          this.YOY = 0;
        } else {
          this.YOY = res.data.data[0].value;
        }

        this.detailInfo.chart1 = chart1;
        this.initEchart();
      }).catch((wrong)=>{ }).finally(()=>{
        this.isLoading = false
      })
    },
    initEchart() {
      var dom = document.getElementById('CarbonOverview');
      let chartInit = echarts.init(dom);
      let colornb = parseInt(this.detailInfo.chart2[0].value)/parseInt(this.detailInfo.chart2[1].value * 2)
      
      if(parseInt(this.detailInfo.chart2[1].value * 2) == 0){
        colornb = 0
      }else{
        
      }
      console.log(colornb)
      var option = option = {
          
          color: ["#37A2DA", "#32C5E9", "#67E0E3"],
          
          series: [
            {
              name: '业务指标',
              type: 'gauge',
              radius: "100%",
              center:['50%', '65%'],
              startAngle: 210,
              endAngle: -30,
              min: 0,
              max: parseInt(this.detailInfo.chart2[1].value) * 2,
              detail: {
                  
                  color:"white",
                  fontSize:25,
                  formatter: function (params) {
                    let a = params + "{a|\n总计(tCO₂)}";
                    return a;
                  },
                  rich: {
                    a: {
                        color: 'rgb(171, 168, 168)',
                        padding: [10, 0, 0, 0],
                        lineHeight: 10
                    },
                }
              },
              pointer: {
                  show: true,
                  itemStyle:{
                    color:"rgba(105,252,189,1)"
                  },
                  length: '50%',
                  radius: '10%',
                  width: 2, //指针粗细
              },
              axisTick: {
              // 刻度
              show: true,
              distance:0,
              length:3,
              lineStyle:{
                color:"white"
              }
              },
              splitLine: {
                  // 分割线
                  show: false
              },
              axisLabel: {
                  // 刻度标签
                  distance: -5,
                  color: 'white',
                  fontSize: 10,
                  formatter: function (value) {
                      return parseInt(value);
                  }
              },
              title: {
                // 标题
                show: true,
                fontSize:14,
                color:"white",
                offsetCenter: [0, "-60%"],
                formatter: function () {
                  //格式化函数
                  return "";
                },
              },
              axisLine: {
                  show: true,
                  lineStyle: {
                      width: 10,
                      shadowBlur: 0,
                      color: [
                          [colornb, new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                  offset: 0,
                                  color: 'rgba(105,252,189,1)'
                              },
                              {
                                  offset: 1,
                                  color: '#58b1c9',
                                  // color:'rgba(17,24,43,0)'
                              }
                          ], false)],
                          [1, new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                  offset: 0,
                                  color: '#347097'
                              },
                              {
                                  offset: 1,
                                  color: '#50a9e2',
                                  // color:'rgba(17,24,43,0)'
                              }
                          ], false)]
                      ]
                  }
              },
              data: [{
                  value: parseInt(this.detailInfo.chart2[0].value),
                  name:'\n目标值',
              }]

          },
          
          // 内圆
        {
            "name": '内圆',
            "type": 'pie',
            "hoverAnimation": false,
            "legendHoverLink": false,
            center:['50%', '70%'],
            "radius": '4%',
            "z": 4,

            "labelLine": {
                "normal": {
                    "show": false
                }
            },
            "data": [{
                "value": 0,
            }, {
                "value": 10,

                itemStyle: {
                    normal: {
                        color: "#0E1327"
                    },
                    emphasis: {
                        color: "#0E1327"
                    }
                }
            }]
        },
        // 圆环
        {
            "name": '小圆形',
            "type": 'pie',
            "hoverAnimation": false,
            "legendHoverLink": false,
            "radius": ['4%', '6%'],
            center:['50%', '65%'],
            "z": 5,
            "labelLine": {
                "normal": {
                    "show": false
                }
            },
            "data": [{
                "value": 0,
            }, {
                "value": 10,

                itemStyle: {
                    normal: {
                        color: "rgba(219,219,219,1)",
                    },

                }
            }]
        }]
      };
      setTimeout(() => { chartInit.resize() }, 200)
      chartInit.setOption(option);
      window.addEventListener("resize", function () {
        chartInit.resize()
      });
    }

  },
  mounted() {
    this.getData()
    
  }
}
</script>
                
<style lang="less" scoped>
.graphleft {
  width: 100%;
  height: 100%;
  ul>li {
    width: 281px;
    height: 85px;
    display: flex;
    flex-direction: column;
    background-image: url(../../../../assets/icon/icon/lang01.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

#CarbonOverview {
  width: 100%;
  height: calc(100% - 38px);
}
</style>
                