<template>
  <div class="workOverview">
    <div class="grouptitle">Installed Capacity <span>装机容量</span></div>
    <img src="@/assets/imgs/big/xian.svg" style="width: 100%;
      height: 16px; height: 16px;" />
    <ul>
      <li v-for="(item, index) in listData" :key="index">
        <div><img :src="item.img" alt="" /></div>
        <div>
          <span style="font-size: 0.15rem;">{{ item.name }}<span style="font-size: 0.15rem;">{{ item.unit }}</span></span>
          <span style="font-size: 0.3rem;">
            {{ item.value }}
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>
  
<script>

export default {
  name: "workOverview",
  components: {
  },
  props: {},
  watch: {
    "currentTime": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    "currenttype": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.searchObj.TYPE = newVal
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    "bigdeptcode": {
      handler: function () {
        this.getData();
      },
      deep: true,
      immediate: true,
    },
    "changeValue": {
      handler: function () {
        this.getData();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      searchObj: {
        TYPE: "按年", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      cost:0,
      total:0,
      value:0,
      value2:0,
      detailInfo:{},
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中
      asset:[]

    };
  },
  computed: {
    currentTime() {
      return this.$store.state.timer;
    },
    currenttype(){
      return this.$store.state.financetype;
    },
    currentSelectDeptInfo() {
      return this.$store.state.overviewdept;
    },
    listData(){
      let capacity = 0
      if(this.currentSelectDeptInfo == '221027388'){
        capacity = 948
      }else if(this.currentSelectDeptInfo == '221027423'){
        capacity = 560
      }else if(this.currentSelectDeptInfo == '221027390'){
        capacity = 323
      }else if(this.currentSelectDeptInfo == '221027422'){
        capacity = 65
      }
      let arr = [
        {
          name: '光伏装机',
           unit:"(kWp)",
          value: capacity,
          img: require('@/assets/imgs/big/icon21.png'),
          site: '个'
        },
        {
          name: '储能装机',
          value: "250/853",
          unit:"(kW/kWh)",
          img: require('@/assets/imgs/big/icon19.png'),
          site: '个'
        },
        {
          name: '累计发电量',
          value: this.toThousandsSeparator(parseInt(this.total)),
           unit:"(kWh)",
          img: require('@/assets/imgs/big/icon12.png'),
          site: '套'
        },
        {
          name: '累计收益',
          unit:"(元)",
          value: this.toThousandsSeparator(parseInt(this.cost)),
          img: require('@/assets/imgs/big/icon16.png'),
          site: '个'
        },
       
      ]
      return arr
    },
    // 当前Dept信息
    bigdeptcode() {
      return this.$store.state.bigdeptcode;
    },
  },
  methods: {
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var obj = {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        };
      return obj;
    },
    getList() {
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/equipment/assetlist",
        method: "post",
        data:{
          deptCode: this.$store.state.overviewdept,
          type:"光伏逆变器"
        }
      }).then((res) => {
        
        let asset = []
        for(let item of res.data.data){
          asset.push(item.assetNumber)
        }
        this.asset = asset
        this.getData2();
      });
    },
    getData() {
          let data = {
            "deptCode":this.$store.state.overviewdept,
          }
          this.isLoading = true
          this.$axios({
            url:Config.dev_url + "/api-apps-v2/api/v2/equipment/installedcapacity",
            method:"post",
            data
          }).then(res=>{
            console.log(res)
            let result = res.data.data
            console.log(result)
            this.detailInfo = result
            this.value = 0
            this.value2 = 0
            for(let item of result){
              if(item.machine_type == "光伏逆变器"){
                this.value = item.sum
              }else if(item.machine_type == "储能BMS"){
                this.value2 =this.value2+parseInt(item.sum)
              }else if(item.machine_type == "储能PCS"){
                this.value2 =this.value2+parseInt(item.sum)
              }
            }
            this.initEchart()
            }).catch((wrong)=>{ }).finally(()=>{
                this.isLoading = false
            })
    },
    getData2() {
      this.detailInfo = {};
      let data = {
        "assetNum": this.asset,
        "variableName": [
          'solarTotalActiveEnergy',
        ]
      }
      let url = Config.dev_url + "/api-apps-v2/api/v2/equipment/variable/real/value";
      this.$axios.post(url, data).then((res) => {
        let allData = res.data.data
        
        console.log(res.data, '')
      });
    },
    getData3() {
      var url = Config.dev_url + "/api-apps-v2/api/v2/energy/renewable/penetration-rate";
      // var url = Config.dev_url + "/api-apps/eskpi/energy/absorption";
      var data = {
        // deptCode:"221027091",
        deptCode: this.$store.state.overviewdept,
      };
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      
      this.$axios
        .post(url, data)
        .then((res) => {
          if (res.data.code == 0) {
            
            this.total = res.data.data.totalGen.toString();
            
           
          } else {
            // this.$message.warning(res.data.msg)
          }
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getData4(){
      let data = {
        deptCode: this.$store.state.overviewdept
      };
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      this.isLoading = true;
      this.$axios({
        // url: Config.dev_url + "/api-apps/ectricity/electric/income",
        url: Config.dev_url + "/api-apps-v2/api/v2/electric/income",
        method: "post",
        data,
      })
        .then((res) => {
          console.log(res.data.data)
          let result = res.data.data;
          this.cost = parseInt(result.self+result.surplus+result.storage)
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getList()
    this.getData()
    this.getData3()
    this.getData4()
  },
};
</script>
  
<style scoped lang="less">
.grouptitle {
  color: white;
  font-size: 0.2rem;

  &>span {
    color: white !important;
    font-size: 18px ;
    margin-right: 10px !important;
  }
}

.workOverview {
  // width: 100%;
 

  ul {
    color: #fff;
    width: 105%;
    height: calc(100% - 35px);
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &>li {
      display: flex;
      align-items: center;
      height: 0.7rem;
      &>div {

        &>img {
          display: inline-block;
          width: 0.6rem;
          height: 0.6rem;
          
        }
      }

      // &>div:nth-child(1) {
      //   width: 0.6rem;
      //   height: 0.6rem;
      // }

      &>div:nth-child(2) {
        margin-left: 14px;
        flex: 1;
        display: flex;
        flex-direction: column;

        &>span {
          flex: 1;
          display: inline-flex;
        }

        &>span:nth-child(1) {
          margin: 10px 0;
          font-size:  0.2rem;
          line-height: 0.2rem;
          color: rgba(243,243,243,0.8);
        }

        &>span:nth-child(2) {
          font-size: 0.2rem;
          line-height: 0.3rem;

          &>p {
            font-size: 0.17rem;
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
}
</style>