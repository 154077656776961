<template>
  <div class="workOverview">
    <div class="grouptitle">Overview of Work Orders<span> 工单总览</span></div>
    <img src="@/assets/imgs/big/xian.svg" style="width: 100%;
      height: 16px; height: 16px;" />
    <ul>
      <li v-for="(item, index) in listData" :key="index">
        <div><img :src="item.img" alt="" /></div>
        <div>
          <span>{{ item.name }}</span>
          <span>
            {{ item.value }}
            <p>{{ item.site }}</p>
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>
  
<script>

export default {
  name: "workOverview",
  components: {
  },
  props: {},
  watch: {
    "bigdeptcode": {
      handler: function () {
        this.getData();
      },
      deep: true,
      immediate: true,
    },
    "changeValue": {
      handler: function () {
        this.getData();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      listData: [
        {
          name: '本年累计工单',
          value: '',
          img: require('@/assets/icon/icon/year.svg'),
          site: '个'
        },
        {
          name: '逾期工单',
          value: '',
          img: require('@/assets/icon/icon/nosite.svg'),
          site: '个'
        },
        {
          name: '本月累计工单',
          value: '',
          img: require('@/assets/icon/icon/month.svg'),
          site: '套'
        },
        {
          name: '未启动工单',
          value: '',
          img: require('@/assets/icon/icon/no.svg'),
          site: '个'
        },
        {
          name: '今日累计工单',
          value: '',
          img: require('@/assets/icon/icon/day.svg'),
          site: '项'
        },
        {
          name: '已启动未完成工单',
          value: '',
          img: require('@/assets/icon/icon/yesNo.svg'),
          site: '个'
        }
      ],
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中


    };
  },
  computed: {
    // 当前Dept信息
    bigdeptcode() {
      return this.$store.state.bigdeptcode;
    },
  },
  methods: {
    getData() {
      let data = { "equipment": "221027388"
        // this.bigdeptcode 
      }
      this.isLoading = true;
      this.$axios({
        url: Config.dev_url + "/api-apps/WorkOrder/orderDisplay",
        method: "POST",
        data,
      })
        .then((res) => {
          let result = res.data;
          this.$nextTick(() => {
            this.listData = [
              {
                name: '本年累计工单',
                value: result.thisYear,
                img: require('@/assets/icon/icon/year.svg'),
                site: '个'
              },
              {
                name: '逾期工单',
                value: result.yq,
                img: require('@/assets/icon/icon/nosite.svg'),
                site: '个'
              },
              {
                name: '本月累计工单',
                value: result.thisMonth,
                img: require('@/assets/icon/icon/month.svg'),
                site: '套'
              },
              {
                name: '未启动工单',
                value: result.wqd,
                img: require('@/assets/icon/icon/no.svg'),
                site: '个'
              },
              {
                name: '今日累计工单',
                value: result.thisDay,
                img: require('@/assets/icon/icon/day.svg'),
                site: '项'
              },
              {
                name: '已启动未完成工单',
                value: result.yqd,
                img: require('@/assets/icon/icon/yesNo.svg'),
                site: '个'
              }
            ]
          })
        })
        .catch((wrong) => { })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
  },
};
</script>
  
<style scoped lang="less">
.grouptitle {
  color: white;
  font-size: 0.2rem;

  &>span {
    color: white !important;
    font-size: 18px ;
    margin-right: 10px !important;
  }
}

.workOverview {
  // width: 100%;
 

  ul {
    color: #fff;
    width: 100%;
    height: calc(100% - 35px);
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &>li {
      display: flex;
      align-items: center;
      height: 0.7rem;
      &>div {

        &>img {
          display: inline-block;
          width: 0.6rem;
          height: 0.6rem;
        }
      }

      // &>div:nth-child(1) {
      //   width: 0.6rem;
      //   height: 0.6rem;
      // }

      &>div:nth-child(2) {
        margin-left: 14px;
        flex: 1;
        display: flex;
        flex-direction: column;

        &>span {
          flex: 1;
          display: inline-flex;
        }

        &>span:nth-child(1) {
          margin: 10px 0;
          font-size: 0.17rem;
          line-height: 0.2rem;
        }

        &>span:nth-child(2) {
          font-size: 0.2rem;
          line-height: 0.3rem;

          &>p {
            font-size: 0.17rem;
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
}
</style>