<template>
  <div class="graphright" style="position: relative;">
    <div class="type-group" style="position: absolute;top: 31px;z-index: 999;">
      <!-- <span
        class="pick-type"
        v-for="(item, idx) in timetType"
        :key="idx"
        @click="changeType(item.code)"
      >
        <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
          item.name
        }}</font>
      </span> -->
    </div>
    <siteName :objname="objname"></siteName>
    <div ref="graphright" id="Energyflow"></div>
  </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
import siteName from "../../financeView/site";
export default {
  name: 'graphright',
  props: {},
  components: { siteName },
  data() {
    return {
      flowdata:{
        links:[{model: "配电", source: "市电", target: "昆山园区", value: 5},
        {model: "配电", source: "光伏", target: "昆山园区", value: 5},
        {model: "配电", source: "昆山园区", target: "行政楼(kW)", value: 2},
        {model: "配电", source: "昆山园区", target: "能源楼(kW)", value: 5},
        {model: "配电", source: "昆山园区", target: "车间(kW)", value: 3}],
        nodes:[{name: "昆山园区"},{name: "光伏", type: 0},{name: "市电", type: 0},{name: "能源楼(kW)", type: 1},{name: "行政楼(kW)", type: 1},{name: "车间(kW)", type: 1}]
      },
      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
        
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      searchObj: {
        TYPE: "按年", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      detailInfo: {
        chart1: {},
      },
      objname: {
        name: 'Energy Flow Direction',
        site: '能源流向'
      },
      newarr1:[],
      colorList: [
        "#cb2bd5",
        "#f03040",
        "#ff7300",
        "#ffd800",
        "#329484",
        "#686868",
        "#3366ff",
        "#1b0dd8",
        "#f2ac66",
        "#ff7300",
        "#f03040",
        "#ff7300",
        "#469E3F",
        "#329484",
        "#3366FF",
      ],
    }
  },
  watch: {
    "currentTime": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.getStructure()
        }
      },
      deep: true,
      immediate: true,
    },
    "currenttype": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.searchObj.TYPE = newVal
          this.getStructure()
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    currentTime() {
      return this.$store.state.timer;
    },
    currenttype(){
      return this.$store.state.financetype;
    },
    currentSelectDeptInfo() {
      return this.$store.state.overviewdept;
    },
  },
  methods: {
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day1h",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getStructure()
    },
    
    initEchart() {


      var dom = document.getElementById('Energyflow');
      let chartInit = echarts.init(dom);
      var { nodes, links } = this.flowdata;
      var color = this.colorList;
      nodes.forEach((item, idx) => {
        if (item.type == 0) {
          var label = { normal: { position: "right" } };
          nodes[idx].label = label;
        } else {
          var label = { normal: { position: "left" } };
          nodes[idx].label = label;
        }
      });
      console.log(links)
      var option = {
        animation: true,
        layoutAnimation: false,

        color: this.colorList,
        grid: {
          containLabel: true,
        },
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
        },
        series: [
          {
            nodeGap: 20,
            nodeWidth: 5,
            type: "sankey",
            animationDurationUpdate: 2000,
            animationEasingUpdate: "quinticInOut",
            left: "1%",
            right: "5%",
            data: nodes,
            links: links,
            label: {
            show: true,
            
              fontSize: 16,
              color:"white"
            },
            lineStyle: {
              color: "source",
              curveness: 0.6,
            },
          },
        ],
      };
      
      setTimeout(() => { chartInit.resize() }, 200)
      
      chartInit.setOption(option);
      window.addEventListener("resize", function () {
        chartInit.resize()
      });
    },
    getStructure() {
      let data = { "deptCode": "221027388" }
      // let data = { "deptCode": "221027059" }
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      let url = Config.dev_url + "/api-apps-v2/api/v2/energy/org/flow";
      this.$axios.post(url, data).then((res) => {
        this.flowdata.links = res.data.data.links
        this.flowdata.nodes = res.data.data.nodes
        for(let item of this.flowdata.nodes){
          if(item.name == "昆山园区"){
            item.name ="昆山园区(kWh)"
          }
        }
        for(let item of this.flowdata.links){
          if(item.target == "昆山园区"){
            item.target ="昆山园区(kWh)"
          }
          if(item.source == "昆山园区"){
            item.source ="昆山园区(kWh)"
          }
        }
        this.initEchart()
      });
    },
    getData() {
      let data = {
        deptCode: "221027422",
        equipment: "221027422",
      };
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      data.type = this.searchObj.checked ? 1 : 0;
      this.isLoading = true;
      this.year = data.startTime.split("-")[0];
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/energy/total",
        method: "post",
        data,
      })
        .then((res) => {
          let result = res.data.data;
          this.flowdata.links[2].value = result.totalPower
          this.getData2()
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getData2() {
      let data = {
        deptCode: "221027390",
        equipment: "221027390",
      };
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      data.type = this.searchObj.checked ? 1 : 0;
      this.isLoading = true;
      this.year = data.startTime.split("-")[0];
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/energy/total",
        method: "post",
        data,
      })
        .then((res) => {
          let result = res.data.data;
          this.flowdata.links[3].value = result.totalPower
          this.getData3()
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getData3() {
      let data = {
        deptCode: "221027423",
        equipment: "221027423",
      };
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      data.type = this.searchObj.checked ? 1 : 0;
      this.isLoading = true;
      this.year = data.startTime.split("-")[0];
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/energy/total",
        method: "post",
        data,
      })
        .then((res) => {
          let result = res.data.data;
          this.flowdata.links[4].value = result.totalPower
          this.initEchart()
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    // this.getData()
    this.getStructure()
    
  }
}
</script>
                
<style lang="less" scoped>
.pick-type font {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color:rgba(255,255,255,0.7);
  line-height: 18px;
}
.type-group .pick-type .isactive-type{
  color:#DEFFFF;
}
.graphright {
  width: 100%;
  height: 100%;
  ul>li {
    width: 281px;
    height: 85px;
    display: flex;
    flex-direction: column;
    background-image: url(../../../../assets/icon/icon/lang01.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

#Energyflow {
  width: 100%;
  height: calc(100% - 38px);
}
</style>
                