<template>
  <div class="graphright" style="position: relative;">
    <div class="type-group" style="position: absolute;top: 31px;z-index: 999;">
      <!-- <span
        class="pick-type"
        v-for="(item, idx) in timetType"
        :key="idx"
        @click="changeType(item.code)"
      >
        <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
          item.name
        }}</font>
      </span> -->
    </div>
    <siteName :objname="objname"></siteName>
    <div ref="graphright" id="graphright"></div>
  </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
import siteName from "../../financeView/site";
export default {
  name: 'graphright',
  props: {},
  components: { siteName },
  data() {
    return {
      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
        
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      searchObj: {
        TYPE: "按年", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      detailInfo: {
        chart1: {},
      },
      objname: {
        name: 'Carbon Emissions Details',
        site: '碳排放详情'
      },
      newarr1:[]
    }
  },
  watch: {
    "currentTime": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    "currenttype": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          // this.searchObj.TYPE = "按年"
          
          this.searchObj.TYPE = newVal
          if(newVal == "按天"){
            this.searchObj.TYPE = "按月"
          }
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    currentTime() {
      return this.$store.state.timer;
    },
    currenttype(){
      return this.$store.state.financetype;
    },
    currentSelectDeptInfo() {
      return this.$store.state.overviewdept;
    },
  },
  methods: {
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day15m",
          starttime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endtime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          starttime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endtime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          starttime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endtime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          starttime: VALUE.startOf("year").format("YYYY-MM"),
          endtime: VALUE.endOf("year").format("YYYY-MM"),
        },
      };
      return obj[TYPE];
    },
    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    getData() {
      let data = {
        // code:"221027091",
        code: this.$store.state.overviewdept,
      };
      if (this.searchObj.TYPE == "按季") {
        Object.assign(data, this.getTimes(this.value));
      } else {
        Object.assign(data, this.transformSearch());
      }
      let url = Config.dev_url + "/api-apps-v2/api/v2/carbon_emissions/details"
      // let url = Config.dev_url + "/api-apps/carbonManagement/getCarbonEmissionDetail"
      this.$axios
        .post(url, data)
        .then((res) => {
          console.log(res.data);
          
          
          this.detailInfo = {};
          var chart1 = {
            categoryData: res.data.data.categoryData,
            seriesData: [],
          };
          chart1.seriesData = res.data.data.seriesData;
          this.detailInfo.chart1 = chart1;
          let newarr = [];
          this.detailInfo.chart1.seriesData.forEach((item) => {
            item.value.forEach((val) => {
              newarr.push(val);
            });
          });
          
          this.initEchart();
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    initEchart() {
      var { categoryData, seriesData } = this.detailInfo.chart1;
      console.log(this.detailInfo.chart1)
      var dom = document.getElementById('graphright');
      let chartInit = echarts.init(dom);
      var series = [];
      seriesData.forEach((item, idx) => {
        item.value.forEach(function (items, index) {
          if (items) {
            item.value[index] = items.toFixed(2);
          }
        });
        if(item.name =='储能收益'){

        }else{
          var obj = {
          type: "line",
          name: item.name,
          data: item.value,
          animationDurationUpdate: 2000,
          animationEasingUpdate: "quinticInOut",
          smooth: 0.3,
          showSymbol: false,
          emphasis: {
            focus: "series",
          },
          symbol: "circle",
        };

        series.push(obj);
        }
      });
      var option = {
        animation: true,
        layoutAnimation: false,
        grid: {
          right: '0%',
          left: '0%',
          bottom: "7%",
          top: "15%",
          containLabel: true,
        },
        legend: {
          right: 0,
          top: "0%",
          // orient: "vertical",
          icon: "rect", //图例形状
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        xAxis: {
          type: "category",
          data: categoryData,
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "white",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
            fontSize: 14,
          },
        },
        yAxis: {
          name: "(tCO₂)",
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
            fontSize: 14,
            padding:[0,0,0,20]
          },
          axisLine: {
            lineStyle: {
              color: "white",
            },
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
            fontSize: 14,
           
          },
          splitLine: {
                show: false,
                lineStyle: {
                    color: '#192a44'
                },
            },
          // 控制数据参数最大值和最小值
          // interval: 200,
          // max: 1000
        },

        // 控住柱状图样式
        // series:series,
        series: series,
        
      };
      setTimeout(() => { chartInit.resize() }, 200)
      
      chartInit.setOption(option);
      window.addEventListener("resize", function () {
        chartInit.resize()
      });
    }
  },
  mounted() {
    this.getData()
    // this.initEchart()
  }
}
</script>
                
<style lang="less" scoped>
.pick-type font {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color:rgba(255,255,255,0.7);
  line-height: 18px;
}
.type-group .pick-type .isactive-type{
  color:#DEFFFF;
}
.graphright {
  width: 100%;
  height: 100%;
  ul>li {
    width: 281px;
    height: 85px;
    display: flex;
    flex-direction: column;
    background-image: url(../../../../assets/icon/icon/lang01.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

#graphright {
  width: 100%;
 height: calc(100% - 38px);
}
</style>
                