<template>
  <div class="graphleft">
    <siteName :objname="objname"></siteName>
    <div ref="graphleft" id="graphleft"></div>
  </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
import siteName from "../../financeView/site";
export default {
  name: 'graphleft',
  props: {},
  components: { siteName },
  data() {
    return {
      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
        
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      searchObj: {
        TYPE: "按年", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      detailInfo: {
        chart1: {},
      },
      objname: {
        name: 'Carbon Emission Usage',
        site: '碳排放强度'
      },
      newarr1:[]
    }
  },
  watch: {
    "currentTime": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          // this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    "currenttype": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.searchObj.TYPE = newVal
          // this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          // this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    currentTime() {
      return this.$store.state.timer;
    },
    currenttype(){
      return this.$store.state.financetype;
    },
    currentSelectDeptInfo() {
      return this.$store.state.overviewdept;
    },
  },
  methods: {
    initEchart() {
      var dom = document.getElementById('graphleft');
      let chartInit = echarts.init(dom);
      var option = {
        backgroundColor: 'rgba(0,0,0,0)',
        color: '',
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        // tooltip: {},
        grid: {
          top: '15%',
          bottom: '10%',
          left: '0%',
          right: '0%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: this.detailInfo.chart1.categoryData,
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
            fontSize: 14,
          },
        },
        yAxis: {
          name: "(吨/亿元)",
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
            fontSize: 14,
            padding:[0,0,0,40]
          },
          axisLine: {
            lineStyle: {
              color: "white",
            },
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
            fontSize: 14,
           
          },
          splitLine: {
                show: false,
                lineStyle: {
                    color: '#192a44'
                },
            },
          // 控制数据参数最大值和最小值
          // interval: 200,
          // max: 1000
        },
        series: [
          {
            data: this.detailInfo.chart1.seriesData,
            type: 'bar',
            barWidth:10,
            itemStyle: {
              
              color: {
                type: 'linear',
                x: 0,  //右
                y: 1,  //下
                x2: 0,  //左
                y2: 0,  //上
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(0,135,254,0)'
                  },
                  {
                    offset: 0.7,
                    color: 'rgba(79,172,254,1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(79,172,254,1)' 
                  }
                ]
              }
            },
          }
        ]
      };
      setTimeout(() => { chartInit.resize() }, 200)
      chartInit.setOption(option);
      window.addEventListener("resize", function () {
        chartInit.resize()
      });
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
          queryType: "year",
          starttime: VALUE.startOf("year").format("YYYY-MM-DD HH:mm:ss"),
          endtime: VALUE.endOf("year").format("YYYY-MM-DD HH:mm:ss"),
        };
      return obj;
    },
    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    getData() {
      let data = {
        // code:"221027091",
        code: this.$store.state.overviewdept,
      };
      if (this.searchObj.TYPE == "按季") {
        Object.assign(data, this.getTimes(this.value));
      } else {
        Object.assign(data, this.transformSearch());
      }
      let url = Config.dev_url + "/api-apps-v2/api/v2/carbon_emissions/intensity_allmonth"
      // let url = Config.dev_url + "/api-apps/carbonManagement/getCarbonEmissionDetail"
      this.$axios
        .post(url, data)
        .then((res) => {
          console.log(res);
          
          
          this.detailInfo = {};
          var chart1 = {
            categoryData: res.data.data.categories,
            seriesData: [],
          };
          chart1.seriesData = res.data.data.currentValues;
          this.detailInfo.chart1 = chart1
          
          this.initEchart();
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    
    this.getData()
  }
}
</script>
                
<style lang="less" scoped>
.graphleft {
  width: 100%;
  height: 100%;
  ul>li {
    width: 281px;
    height: 85px;
    display: flex;
    flex-direction: column;
    background-image: url(../../../../assets/icon/icon/lang01.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

#graphleft {
  width: 100%;
  height: calc(100% - 38px);
}
</style>
                