<template>
    <div class="main">
      <div class="left">
        <overview :changeValue="changeValue"></overview>
        <listTable :objSite="leftobj" ref="leftobj"></listTable>
        <pieGrad :objAll="objcont" :changeValue="changeValue"></pieGrad>
      </div>
      <div class="mind">
        <atlasMap></atlasMap>
      </div>
      <div class="right">
        <pieGrad :objAll="objUp" :changeValue="changeValue"></pieGrad>
        <listTable :objSite="rightobj" ref="rightobj"></listTable>
        <pieGrad :objAll="objDown" :changeValue="changeValue"></pieGrad>
      </div>
    </div>
</template>

<script>
import wrapperAll from "@/views/bigscreen/subgroup/screen";
import overview from "@/views/bigscreen/subgroup/workOverview.vue";
import listTable from "@/views/bigscreen/subgroup/listTable.vue";
import pieGrad from "@/views/bigscreen/subgroup/pieGrad.vue";
import atlasMap from "@/views/bigscreen/subgroup/atlasMap.vue";
import topTitle from "@/views/bigscreen/financeView/title.vue";
export default {
  created() {

  },
  data() {
    return {
      nameSite: 'ABB 智慧能源平台',
      isFullMode: false,
      objcont: {
        name: '设备连接状态',
        nameSite: 'Device Connection Status',
        url: '/api-apps-v2/api/v2/equipment/status_stat',
        site: 'cont'
      },
      objDown: {
        name: '网关连接状态',
        nameSite: 'Gateway Connection Status',
        url: '/api-apps-v2/api/v2/master/edge/statistics',
        site: 'down'
      },
      objUp: {
        name: '事件状态',
        nameSite: 'Event Status',
        url: '/api-apps/eventInfo/statsByStatus',
        site: 'up'
      },
      leftobj: {
        name: '最新工单',
        nameSite: 'Current Installations',
        url: '/api-apps/app/WORKORDER/list',
        site: 'left'
      },
      rightobj: {
        name: '最新事件',
        nameSite: 'Events Lists',
        url: '/api-apps/app/EVENTINFORMATION/list',
        site: 'right'
      },
      options: [{
        value: '221027388',
        label: '昆山园区'
      }, {
        value: '221027423',
        label: '车间北配电房'
      }, {
        value: '221027422',
        label: '行政楼配电房'
      }, {
        value: '221027390',
        label: '能源楼配电房'
      },],
      value: '221027388',
      changeValue: '221027388'
    }
  },
  components: {
    wrapperAll,
    overview,
    listTable,
    pieGrad,
    atlasMap,
    topTitle
  },
  computed: {

  },
  methods: {
    delCard(value) {
      console.log(value)
      this.changeValue = value
      this.$store.commit("setbigdeptcode", value)
      // this.$store.commit("bigdeptcode", value)

    },
  },
  mounted() {
    document.body.style.overflowY = 'scroll'
  }
}
</script>

<style lang="less" scoped>
.bigheard {
  background-image: url(../../assets/imgs/big/header.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  // width: calc(100% - 62px);
  width: 100%;
  height: 100%;
  position: absolute;
  left: -32px;
  display: flex;
  justify-content: center;
  align-items: center;

  &>div {
    display: flex;
    align-items: center;

    &>img {
      width: 71px;
      height: 27px;
    }
  }

  .text {
    color: white;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 10px;
    margin-left: 10px;
  }
}

.bigbg {
  width: 100%;
  height: calc(100% - 38px) !important;
  background-image: url(../../assets/imgs/big/bg_new.jpg);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background: rgb(26, 46, 69);
  box-sizing: border-box;
  z-index: -2;
}

.top {
  width: 100%;
  height: 78px;

  .bigelement {
    &>div {
      width: 100%;
      height: 70px;
      position: absolute;
      top: 0px;
      display: flex;
    }
  }
}

.main {
  width: 100%;
  padding: 0 32px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.mind {
  width: 44%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &>div {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.left {
  width: 28%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  &>div {
    width: calc(100% - 40px);
    margin-right: 40px;
    height: 30%;
    display: flex;
    flex-direction: column;
  }

  &>div:nth-child(3) {
    margin-bottom: 0
  }
}

.right {
  width: 28%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  &>div {
    width: calc(100% - 40px);
    margin-left: 40px;
    height: 30%;
    display: flex;
    flex-direction: column;
  }

  &>div:nth-child(3) {
    margin-bottom: 0
  }
}
.grouptitle{
  &>span {
    color: white !important;
    font-size: 18px ;
    margin-right: 10px !important;
  }
}
</style>