<template>
  <div class="pieGrad">
    <div class="grouptitle">{{ objAll.nameSite }}<span> {{ objAll.name }}</span></div>
    <img src="@/assets/imgs/big/xian.svg" style="width: 100%;
      height: 16px; height: 16px;" />
    <div ref="chart1" :id="echartsID.id" style="height: calc(100% - 50px);" />
  </div>
</template>
  
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
export default {
  name: "pieGrad",
  components: {},
  props: {
    echartsID: {
      type: Object,
      default: function () {
        return { id: 'chart0' + Math.random() }
      }
    },
    objAll: {
      type: Object,
      default: function () {
        return {
          name: '',
          nameSite: '',
          url: '',
          site: ''
        };
      },
    },
  },
  watch: {
    "bigdeptcode": {
      handler: function () {
        this.getData();
      },
      deep: true,
      immediate: true,
    },
    "changeValue": {
      handler: function () {
        this.getData();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      num: 0,
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中
    };
  },
  computed: {
    // 当前Dept信息
    bigdeptcode() {
      return this.$store.state.bigdeptcode;
    },
  },
  methods: {
    getData() {
      
      let data = {};
      if (this.objAll.site == 'cont') {
        // data = { deptCode: this.bigdeptcode }
        data = { deptCode: '221027388' }
      } else if (this.objAll.site == 'up') {
        data = { "deptCode": '221027388', "startTime": "2023-06-01 00:00:00", "endTime": "2023-06-30 23:59:59", "dateType": "本月" }
      } else if (this.objAll.site == 'down') {
        // data = { code: this.bigdeptcode }
        data = { deptCode: '221027388' }
      }else if (this.objAll.site == 'structure') {
        // data = { code: this.bigdeptcode }
        data = { deptCode: '221027388' }
      }
      
      this.$axios({
        url: Config.dev_url + this.objAll.url,
        method: "post",
        data,
      }).then((res) => {
        this.isLoading = true;
        let result = res.data.data;
        let dataAll = [], colorSite = ['37FFC9'];
        if (this.objAll.site == 'cont') {
          dataAll = [
            { name: '在线设备', value: Number(result.online) },
            { name: '离线设备', value: Number(result.offline) },
            { name: '异常设备', value: Number(result.hitch) },
          ]
          colorSite = ['#37FFC9', '#FFE777', '#19D6FF']
        } else if (this.objAll.site == 'up') {
          if (result.valueList.length !== 0) {
            dataAll = [];
            result.valueList.forEach((item) => {
              dataAll.push({ name: item.alertstatus, value: Number(item.cnt) })
            })
          } else {
            dataAll = [];
          }
          colorSite = ['#37FFC9', '#FFE777', '#19D6FF']
        } else if (this.objAll.site == 'down') {
          dataAll = [
            { name: '离线', value: Number(result.total - result.online) },
            { name: '在线', value: result.online }
          ]
          if(Number(result.total - result.online) == 0){
            colorSite = ['#19D6FF']
          }else{
            colorSite = ['#ff1111', '#19D6FF']
          }
          
        }else if (this.objAll.site == 'structure') {
          dataAll = [
            { name: '市电', value: Number(result[1].value) },
            { name: '光伏', value: result[2].value }
          ]
          colorSite = ['#4FACFE', '#69FCBD']
        }
        this.initEchart(dataAll, colorSite);
        this.isLoading = false;
      });
    },
    initEchart(dataAll, colorSite) {
      var dom = document.getElementById(this.echartsID.id);
      let chartInit = echarts.init(dom);
      //颜色16进制换算rgba,添加透明度
      function hexToRgba(hex, opacity) {
        return (
          'rgba(' +
          parseInt('0x' + hex.slice(1, 3)) +
          ',' +
          parseInt('0x' + hex.slice(3, 5)) +
          ',' +
          parseInt('0x' + hex.slice(5, 7)) +
          ',' +
          opacity +
          ')'
        );
      }
      // 数据
      var radius = ['30%', '45%'];
      var radius2 = [Number(radius[1].split('%')[0]) + 0 + '%', Number(radius[1].split('%')[0]) + 15 + '%'];
      var radius3 = [Number(radius[1].split('%')[0]) + 15 + '%', Number(radius[1].split('%')[0]) + 24 + '%'];
      let color = colorSite
      let color1 = [], color2 = [], color3 = [];

      color.forEach((item) => {
        color1.push(item);
        color2.push(hexToRgba(item, 0.7));
        color3.push(hexToRgba(item, 0.4));
      });
      console.log(color1)
      let data1 = [];
      let sum = 0;
      if (dataAll.length !== 0) {
        dataAll.forEach((item) => {
          sum += Number(item.value);
        });
        dataAll.forEach((item) => {
        
          if (item.value !== 0) {
            data1.push(item);
          }
        });
      } else {
        data1 = []
      }
      console.log(dataAll)
      console.log(data1)
      var option = {
        backgroundColor: 'rgba(0,0,0,0)',
        tooltip: {
          formatter: (params) => {
            if (params.name !== '' && params.color !== 'transparent') {
              return params.name + ' : ' + params.value + '\n' + '(' + params.percent + '%)';
            }
          },
        },
        legend: {
          top: "center",
          left: '80%',
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
            fontSize: 13,
          },
        },
        grid: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          containLabel: true,
        },
        series: [
          // 最外层圆环
          {
            type: 'pie',
            radius: radius3,
            center: ['40%', '50%'],
            hoverAnimation: false,
            startAngle: 90,
            selectedMode: 'single',
            selectedOffset: 0,
            silent: true,
            label: {
              normal: {
                formatter: params => {
                  if(params.color !== "transparent"){
                    return `${params.name}: ${params.percent} %`
                  }else{
                    return ''
                  }
                },
                color: '#07E4EB'
              }
            },
            labelLine: {
              normal:{
                length: 24,
                length2: 24,
              }
            },
            itemStyle: {
              normal: {
                color: (params) => {
                  return color1[params.dataIndex];
                },
              },
            },
            data: data1,
          },
          {
            type: 'pie',
            radius: radius2,
            center: ['40%', '50%'],
            hoverAnimation: false,
            startAngle: 90,
            selectedMode: 'single',
            selectedOffset: 0,
            label: {
              show: false,
            },
            itemStyle: {
              normal: {
                color: (params) => {
                  return color2[params.dataIndex];
                },
              },
            },
            data: data1,
          },
          {
            type: 'pie',
            radius: radius,
            center: ['40%', '50%'],
            hoverAnimation: false,
            startAngle: 90,
            label: {
              show: false,
            },
            labelLine: {
              normal:{
                length: 45,
                length2: 45,
              }
            },
            selectedMode: 'single',
            selectedOffset: 0,
            itemStyle: {
              normal: {
                color: (params) => {
                  return color3[params.dataIndex];
                },
              },
            },
          data: data1,
          },
        ],
    };

    setTimeout(() => { chartInit.resize() }, 200)
chartInit.setOption(option);
window.addEventListener("resize", function () {
  chartInit.resize()
});
    },
  },
mounted() {
},
};
</script>
  
<style scoped lang="less">
.grouptitle {
  color: white;
  font-size: 0.2rem;

  &>span {
    color: white !important;
    font-size: 18px ;
    margin-right: 10px !important;
  }
}

.pai {
  width: 2.5rem;
  height: 3.3rem;
  background: #142327;
  border: 2px solid #346f71;
  color: white;
  font-size: 2rem;
  text-align: center;
  line-height: 3.3rem;
}
</style>