<template>
  <div class="reduction">
    <siteName :objname="objname"></siteName>
    <ul>
      <li v-for="(item, index) in listData" :key="index">
        <div><img :src="item.img" alt="" /></div>
        <div>
          <span>{{ item.name }}</span>
          <span>
            {{ item.value }}
          </span>
        </div>
      </li>
    </ul>  
  </div>
</template>
<script>
import siteName from "../../financeView/site";
export default {
  name: 'reduction',
  props: {},
  components: { siteName },
  data() {
    return {
      maindata:"",
      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
        
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      searchObj: {
        TYPE: "年", //按天,按周,按月,按年
        TYPE1: "总发电量",
        VALUE: this.$moment(), //日期
        ITEM: "", //选中的项目
      },
      detailInfo: {
        chart1: {},
        chart2:{}
      },
      newarr1:[],
      objname: {
        name: 'Carbon Emission Reduction',
        site: '碳减排'
      },
      Carbon:""
    }
  },
  watch: {
    "currentTime": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.getData();
          this.getData2()
        }
      },
      deep: true,
      immediate: true,
    },
    "currenttype": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.searchObj.TYPE = newVal
          if(newVal == "按天"){
            this.searchObj.TYPE = "按月"
          }
          this.getData();
          this.getData2()
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
          this.getData2()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    currentTime() {
      return this.$store.state.timer;
    },
    currenttype(){
      return this.$store.state.financetype;
    },
    currentSelectDeptInfo() {
      return this.$store.state.overviewdept;
    },
    listData(){
      let arr = [
        {
          name: '减排氮氧化物 (吨)',
          value: (parseInt(this.maindata)*0.000015).toFixed(2),
          img: require('@/assets/imgs/big/icon5.png'),
          site: '个'
        },
        {
          name: '减排粉尘 (吨)',
          value: (parseInt(this.maindata)*0.005023).toFixed(2),
          img: require('@/assets/imgs/big/icon6.png'),
          site: '个'
        },
        {
          name: '减排二氧化硫 (吨)',
          value: (parseInt(this.maindata)*0.000328).toFixed(2),
          img: require('@/assets/imgs/big/icon8.png'),
          site: '套'
        },
        {
          name: '减排二氧化碳 (吨)',
          value: this.Carbon,
          img: require('@/assets/imgs/big/icon8.png'),
          site: '套'
        },
      ]
      return  arr
    },
    
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day1h",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    getData() {
      // if(this.searchObj.ITEM == ""){
      //   this.detailInfo = {}
      //   var chart1 = {
      //     categoryData: [],
      //     seriesData: []
      //   }
      //   this.detailInfo.chart1 = chart1
      //   this.initEchart()
      //   return
      // }
      //   let data = {
      //     "deptCode": this.currentSelectDeptInfo.CODE,
      //     "assetNumber":this.searchObj.ITEM,
      //     "check": this.searchObj.TYPE1
      //   }
      let data = {
        deptCode: this.$store.state.overviewdept,
        
        check: this.searchObj.TYPE1,
      };
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      console.log(data);
      this.detailInfo = {};
      this.isLoading = true;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/photovoltaic/generation/ranking",
        method: "post",
        data,
      })
        .then((res) => {
          let value = 0
          for(let item of res.data.data.seriesData[0].value){
             
            value =value+item
          }
          
          this.maindata = value
          console.log(this.maindata*0.000328)
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getData2() {
      
      let data = {
        deptCode:this.$store.state.financeView,
        //  code: "221027091",
      };
      Object.assign(data, this.transformSearch2());
      
      data.queryType = data.type;
      delete data.type;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/photovoltaic/income",
        method: "post",
        data,
      })
        .then((res) => {
          this.detailInfo = {};
          
          let result = res.data.data;
          console.log(result);
          var chart1 = {
            categoryData: result.categonyData,
            seriesData: result.seriesData,
          };
          
          let newarr = [];
          this.detailInfo.chart1 = chart1;
          this.detailInfo.self = result.self.toString();
          this.detailInfo.surplus = result.surplus.toString();
          this.detailInfo.storage = result.carbon.toString();
          this.Carbon  = result.carbon
          let arr = ['自发自用','余电上网','碳排放',];
          this.newarr1 = []
        
          for (const i in this.detailInfo) {
            if (this.detailInfo.hasOwnProperty.call(this.detailInfo, i)) {
              const element = this.detailInfo[i];
              console.log(element);
              if (typeof element == "string") {
                if (element >= 100000000000) {
                  this.newarr1.push({ value: element.substring(0, 5) / 10, unit: "亿" });
                } else if (element >= 10000000000) {
                  this.newarr1.push({ value: element.substring(0, 4) / 10, unit: "亿" });
                } else if (element >= 1000000000) {
                  this.newarr1.push({ value: element.substring(0, 3) / 10, unit: "亿" });
                } else if (element >= 100000000) {
                  this.newarr1.push({ value: element.substring(0, 2) / 10, unit: "亿" });
                } else if (element >= 10000000) {
                  this.newarr1.push({ value: element.substring(0, 4), unit: "万" });
                } else if (element >= 1000000) {
                  this.newarr1.push({ value: element.substring(0, 5)/ 100, unit: "万" });
                } else if (element >= 100000) {
                  this.newarr1.push({ value: element.substring(0, 4)/ 100, unit: "万" });
                } else if (element >= 10000) {
                  this.newarr1.push({ value: element.substring(0, 3) / 100, unit: "万" });
                } else if (element >= 1000) {
                  this.newarr1.push({ value: element, unit: "元" })
                } else if (element <= -100000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 7) / 10, unit: "亿" });
                } else if (element <= -100000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 6) / 10, unit: "亿" });
                } else if (element <= -10000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 5) / 10, unit: "亿" });
                } else if (element <= -1000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 4) / 10, unit: "亿" });
                } else if (element <= -100000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 3) / 10, unit: "亿" });
                } else if (element <= -10000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 5), unit: "万" });
                } else if (element <= -1000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 4), unit: "万" });
                } else if (element <= -100000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 3), unit: "万" });
                } else if (element <= -10000) {
                  this.newarr1.push({ value:"-" + element.substring(1,3) / 10, unit: "万" });
                } else if (element <= -1000) {
                  this.newarr1.push({ value:element, unit: "元" });
                } else {
                  this.newarr1.push({ value:element, unit: "元" });
                }
              }
            }
          }
          this.newarr1.forEach((item,index)=>{
            item['name'] = arr[index]
          })
          this.detailInfo.chart1.seriesData.forEach((item) => {
            item.value.forEach((val) => {
              newarr.push(val);
            });
          });
      
          console.log(this.detailInfo)
        })
        .catch((wrong) => {})
        .finally(() => {
          
        });
    },
    transformSearch2: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day1h",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };;
      return obj[TYPE];
    },
  },
  mounted() {
    this.getData()
    this.getData2()
  }
}
</script>
              
<style lang="less" scoped>
.grouptitle {
  color: white;
  font-size: 0.2rem;

  &>span {
    color: white !important;
    font-size: 18px ;
    margin-right: 10px !important;
  }
}

.reduction {
  // width: 100%;
 

  ul {
    color: #fff;
    width: 100%;
    height: calc(100% - 35px);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    
    &>li {
      display: flex;
      align-items: center;
      height: 0.7rem;
      &>div {

        &>img {
          display: inline-block;
          width: 0.6rem;
          height: 0.6rem;
          
        }
      }

      // &>div:nth-child(1) {
      //   width: 0.6rem;
      //   height: 0.6rem;
      // }

      &>div:nth-child(2) {
        margin-left: 14px;
        flex: 1;
        display: flex;
        flex-direction: column;

        &>span {
          flex: 1;
          display: inline-flex;
        }

        &>span:nth-child(1) {
          margin: 10px 0;
          font-size:  0.2rem;
          line-height: 0.2rem;
          color: rgba(243,243,243,0.8);
        }

        &>span:nth-child(2) {
          font-size: 0.2rem;
          line-height: 0.3rem;

          &>p {
            font-size: 0.17rem;
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
}
</style>