<template>
  <div class="atlasMap" style="position: relative;">
    <div class="grouptitle">Map<span> 地图</span></div>
    <img src="@/assets/imgs/big/xian.svg" style="width: 100%;
      height: 11px; height: 11px; " />
    <div style="position: absolute;flex-direction: column;width: 40px;align-items: center;background: white;z-index: 99;right: 31px;top: 88px;height: 70px;justify-content: space-around;border-radius: 5px;display: flex;">

      <img
          v-show="maps == true"
          @click="clickbtn(false)"
          src="@/assets/imgs/map.svg"
          style="width: 20px; position: relative;  z-index: 999;"
      />
      <img
          v-show="maps == false"
          @click="clickbtn(false)"
          src="@/assets/imgs/map3.svg"
          style="width: 20px; position: relative;  z-index: 999;color:#3366FF"
      />
      <img
          v-show="maps == false"
          @click="clickbtn(true)"
          src="@/assets/imgs/map2.svg"
          style="width: 20px; position: relative;  z-index: 999"
      />
      <img
          v-show="maps == true"
          @click="clickbtn(true)"
          src="@/assets/imgs/map4.svg"
          style="width: 20px; position: relative;  z-index: 999"
      />

    </div>
    <div v-show="maps" :id="echartsID.id1" style="height:calc(100% - 35px) !important"></div>
    <div v-show="!maps" :id="echartsID.id2" style="height: calc(100% - 35px) !important"></div>
  </div>
</template>
<script>
import { mapStyle } from "./mapStyle";
import mapdata from './custom_map_config.json'
export default {
  name: 'atlasMap',
  props: {
    mapHeight: {
      type: String,
    },
    echartsID: {
      type: Object,
      default: function () {
        return { id1: 'container' + Math.random(), id2: 'container2' + Math.random() }
      }
    },
  },
  components: {},
  data() {
    return {
      maps: false,
      points: [],
      map: '',
      map2: '',
      pointSymbol: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAADVUlEQVR4nNWZUVITQRCG/8bySS3hBIYTGIpQ5RvxBMYTSE5AggcQLpCFExhPYDgB65tVQgknMNwglL5Zxfo3KTSY3Zme2Vksv6re6c1Ddv6Z7p6ZXUFqtrI2CrwBijbv2KCLP8wgOKdN8ECO8Xk4RU3SCOhmq/iBXRTFDoAWrKiYAoc42/vAuyiEVo9O9o4dH9BbpcUhyHk5wOmQbRjxAm5GvTjhCLZ5lwaRfYo4oGdGaOFonF+z83VGvRKZ4An6yIcz3ngJF/Aia+Fn8ZXeKq0hZIyzYZ+OF6HZaSJsqjlicg/YOgkTsDkaA1oi7wmRl8yJHA7sAjpZl9XmhF4oV3yKlstt+qFMOQvrbCsRmo3OSEOnCxuXWGFFecQFazEZNfmL6x4KGfDuKc3PivTxZThGBTYB+uDrm8S14I/deSGY0HtOc6Ozd7q3Qa8Um4DN0SGvuzQ3Uhzg9O0+LGhB+F7ksIh4KOtV2w6rgG+8tuBC8Ikj1UUI85nQ/3YjMmQyH9JbQmh+NkcFr24MFaMUS2VzDI5fgK36XDLuW4hhM+sBxUd61TQuwPEAL7YwmnGA1tgu8e8FKJYQPdsr7Wvpj3doWoC1REcLsD7giazdWbSsbGU7/P/39FxcUECb7RJ+AYplih2lzollhRccc4Z79JawCpgCeEZzMeMsrAfNQicbMDwzem4cC6RNQGeUc5S26bnRZf8x1wOLCEtu/UZe83wwobOEUYBxpOZMGU59hlOOKubn6H1Yqb2VsCbyIjobgjHA9pZCeux4j14Ldi6qElgRmg1bHqTHEf9KiIAxfHuWJliRDZ4HzumVYhcQE0b18e6x7AIUazVKhec0poQJsK2aqbjiutLyleQwAcr9JfMRw2fA1km4gPuZBdPoK+EClKZnwVM6F4kUYDhFxWMefSVOgNIZTViRXtFLTPW+p4x4AfPXIlNYX1BZcGybq4gXoIRt8nwEhc4t9QQoqULJsGiVUV/APJRyWN6wVXNkqfll1BegzPdJOeLy4YKdb7ONIo0AJW6Bu2Tct0PjfpF0ApQwEVeM+y7j/px+NGkFKLZzQ5LOK+kFKD4RkRWnjGYEKFUiEnZeaU6A8reIxJ1XmhWg6Ncd0c+yol/hcySmeQEN898L+AVUUWFAovp0PAAAAABJRU5ErkJggg==',
      detailInfo: {

      }
    }
  },
  watch: {
  },
  computed: {
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.bigdeptcode;
    }
  },
  methods: {
    getMap2(points) {
      this.map2 = {}
      // BMap
      let vm = this
      this.map2 = new BMapGL.Map(this.echartsID.id2);
      var centerpoint = new BMapGL.Point(111.20,32.56);  //创建点坐标
      console.log(centerpoint)
      this.map2.centerAndZoom(centerpoint, 6);
      this.map2.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
      if(Object.prototype.toString.call(points) == "[object Array]"){
        
        for(let i=0;i<points.length;i++){
          if (!!points[i].latitude && !!points[i].longitude) {
            var lng = Number(points[i].longitude);//经度
            var lat = Number(points[i].latitude);//纬度

            let point = new BMapGL.Point(lng,lat)
            console.log(point)
          
            var myIcon = new BMapGL.Icon(
                this.pointSymbol,
                new BMapGL.Size(40,40), {
                  imageSize: new BMapGL.Size(40, 40)
                }
            );
            // this.map2.setHeading(64.5);
            // this.map2.setTilt(73);
            var marker = new BMapGL.Marker(point,{icon: myIcon});
            this.map2.addOverlay(marker);
            var that = this
            // marker.addEventListener("click",(e)=>{

            //   vm.$router.push({
            //       path: "/plant1page",
            //       query: {
            //         id: 13
            //       }
            //   })
            // });
            marker.addEventListener("mouseover",(e)=>{
                    var opts = {
                        width : 110,     // 信息窗口宽度
                        height: 110,     // 信息窗口高度
                        title:points[i].fullname
                    }
                    
                    // var sContent =
                    //   "<h4 style='margin:0 0 5px 0;padding:0.2em 0'></h4>"+`<div style='width: 138px;margin-left: 5px;float: left;'><div id='main' style='margin-bottom: 10px;'>${points[i].fullName}</div><div  style='margin-bottom: 6px;'>总设备<span style='margin-left: 30px;'>112</span></div>
                    //    <div  style='margin-bottom: 6px;'>同比上涨<span style='margin-left: 30px;'>58%</span></div>
                    //    <div  style='margin-bottom: 6px;'>总成本<span style='margin-left: 30px;'>58%</span></div>
                    //    <div  style='margin-bottom: 6px;'>总能耗<span style='margin-left: 30px;'>58%</span></div>
                    //    <div  style='margin-bottom: 6px;'>总碳排<span style='margin-left: 30px;'>58%</span></div>
                    //    <div  style='margin-bottom: 6px;'>总事件<span style='margin-left: 30px;'>58%</span></div>
                    //    <div  style='margin-bottom: 6px;'>总工单<span style='margin-left: 30px;'>58%</span></div>
                    //    </div>`
                    var sContent =
                      `<div style='width: 200px;margin-left: 5px;float: left;padding: 0px 8px;margin-top: 10px;'>
                       <div style="display:flex;align-items: center;margin-bottom: 6px;"><div style="width: 10px;height: 10px;background: #2D76FE !important;border-radius: 0px;margin-right: 10px;"></div><div  style=''>设备总数<span style='margin-left: 20px;'>${this.detailInfo.sbCount !== undefined ? this.detailInfo.sbCount : ""}(台)</span></div>
                       </div>
                        <div style="display:flex;align-items: center;margin-bottom: 6px;"><div style="width: 10px;height: 10px;background: #4DB8FF !important;border-radius: 0px;margin-right: 10px;"></div><div  style=''>工单总数<span style='margin-left: 20px;'>${this.detailInfo.gdCount !== undefined ? this.detailInfo.gdCount : ""}(个)</span></div>
                        </div>
                        <div style="display:flex;align-items: center;margin-bottom: 6px;"><div style="width: 10px;height: 10px;background: #FFB200 !important;border-radius: 0px;margin-right: 10px;"></div><div  style=''>事件总数<span style='margin-left: 20px;'>0</span>(个)</div>
                        </div>
                       </div>`
                    
                    
                    var infoWindow = new BMapGL.InfoWindow(sContent, opts);  // 创建信息窗口对象
                   
                    this.map2.openInfoWindow(infoWindow, point); //在point打的位置上进行一个标注
                    
                  }); //给每个点添加滑过事件
            // var size = new BMapGL.Size(-3,23);  // 创建信息窗口对象
            
            // marker.setOffset(size); //开启信息窗口
          }
        }
      }else{
        
        if (!!points.latitude && !!points.longitude) {
          var lng = Number(points.longitude);//经度
          var lat = Number(points.latitude);//纬度
          var point = new BMapGL.Point(lng,lat)
          var myIcon = '';
          var marker = new BMapGL.Marker(point,{icon: myIcon});
          this.map.addOverlay(marker);
          var that = this;
          this.map.centerAndZoom(point, );
          marker.addEventListener("mouseover",(e)=>{
            this.overPoint(e,points)
          }); //给每个点添加滑过事件
          marker.addEventListener("click",(e)=>{
            console.log(vm)


          }); //给每个点添加滑过事件
          var size = new BMapGL.Size(50,50);  // 创建信息窗口对象

          marker.setOffset(size); //开启信息窗口
        }
      }

    },
    getMap3(points) {
      this.map2 = {}
      // BMap
      let vm = this
      this.map2 = new BMapGL.Map(this.echartsID.id2);
      var centerpoint = new BMapGL.Point(95.20,43.56);  //创建点坐标
      console.log(centerpoint)
      this.map2.centerAndZoom(centerpoint, 6);
      this.map2.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
      if(Object.prototype.toString.call(points) == "[object Array]"){
        
        for(let i=0;i<points.length;i++){
          if (!!points[i].latitude && !!points[i].longitude) {
            var lng = Number(points[i].longitude);//经度
            var lat = Number(points[i].latitude);//纬度

            let point = new BMapGL.Point(lng,lat)
            console.log(point)
          
            var myIcon = new BMapGL.Icon(
                this.pointSymbol,
                new BMapGL.Size(40,40), {
                  imageSize: new BMapGL.Size(40, 40)
                }
            );
            // this.map2.setHeading(64.5);
            // this.map2.setTilt(73);
            var marker = new BMapGL.Marker(point,{icon: myIcon});
            this.map2.addOverlay(marker);
            var that = this
            // marker.addEventListener("click",(e)=>{

            //   vm.$router.push({
            //       path: "/plant1page",
            //       query: {
            //         id: 13
            //       }
            //   })
            // });
            marker.addEventListener("mouseover",(e)=>{
                    var opts = {
                        width : 110,     // 信息窗口宽度
                        height: 110,     // 信息窗口高度
                        title:points[i].fullname
                    }
                    
                    // var sContent =
                    //   "<h4 style='margin:0 0 5px 0;padding:0.2em 0'></h4>"+`<div style='width: 138px;margin-left: 5px;float: left;'><div id='main' style='margin-bottom: 10px;'>${points[i].fullName}</div><div  style='margin-bottom: 6px;'>总设备<span style='margin-left: 30px;'>112</span></div>
                    //    <div  style='margin-bottom: 6px;'>同比上涨<span style='margin-left: 30px;'>58%</span></div>
                    //    <div  style='margin-bottom: 6px;'>总成本<span style='margin-left: 30px;'>58%</span></div>
                    //    <div  style='margin-bottom: 6px;'>总能耗<span style='margin-left: 30px;'>58%</span></div>
                    //    <div  style='margin-bottom: 6px;'>总碳排<span style='margin-left: 30px;'>58%</span></div>
                    //    <div  style='margin-bottom: 6px;'>总事件<span style='margin-left: 30px;'>58%</span></div>
                    //    <div  style='margin-bottom: 6px;'>总工单<span style='margin-left: 30px;'>58%</span></div>
                    //    </div>`
                    var sContent =
                      `<div style='width: 200px;margin-left: 5px;float: left;padding: 0px 8px;margin-top: 10px;'>
                       <div style="display:flex;align-items: center;margin-bottom: 6px;"><div style="width: 10px;height: 10px;background: #2D76FE !important;border-radius: 0px;margin-right: 10px;"></div><div  style=''>设备总数<span style='margin-left: 20px;'>${this.detailInfo.sbCount !== undefined ? this.detailInfo.sbCount : ""}(台)</span></div>
                       </div>
                        <div style="display:flex;align-items: center;margin-bottom: 6px;"><div style="width: 10px;height: 10px;background: #4DB8FF !important;border-radius: 0px;margin-right: 10px;"></div><div  style=''>工单总数<span style='margin-left: 20px;'>${this.detailInfo.gdCount !== undefined ? this.detailInfo.gdCount : ""}(个)</span></div>
                        </div>
                        <div style="display:flex;align-items: center;margin-bottom: 6px;"><div style="width: 10px;height: 10px;background: #FFB200 !important;border-radius: 0px;margin-right: 10px;"></div><div  style=''>事件总数<span style='margin-left: 20px;'>0</span>(个)</div>
                        </div>
                       </div>`
                    
                    
                    var infoWindow = new BMapGL.InfoWindow(sContent, opts);  // 创建信息窗口对象
                   
                    this.map2.openInfoWindow(infoWindow, point); //在point打的位置上进行一个标注
                    
                  }); //给每个点添加滑过事件
            // var size = new BMapGL.Size(-3,23);  // 创建信息窗口对象
            
            // marker.setOffset(size); //开启信息窗口
          }
        }
      }else{
        
        if (!!points.latitude && !!points.longitude) {
          var lng = Number(points.longitude);//经度
          var lat = Number(points.latitude);//纬度
          var point = new BMapGL.Point(lng,lat)
          var myIcon = '';
          var marker = new BMapGL.Marker(point,{icon: myIcon});
          this.map.addOverlay(marker);
          var that = this;
          this.map.centerAndZoom(point, );
          marker.addEventListener("mouseover",(e)=>{
            this.overPoint(e,points)
          }); //给每个点添加滑过事件
          marker.addEventListener("click",(e)=>{
            console.log(vm)


          }); //给每个点添加滑过事件
          var size = new BMapGL.Size(50,50);  // 创建信息窗口对象

          marker.setOffset(size); //开启信息窗口
        }
      }

    },
    getMap(points) {
          this.map = {}
          console.log(points)
            // BMap
            let vm = this
            this.map = new BMap.Map(this.echartsID.id1);
            var centerpoint2 = new BMap.Point(95.20,43.56);  //创建点坐标
       
            this.map.centerAndZoom(centerpoint2, 6);
            console.log(centerpoint2)
            this.map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
            if(Object.prototype.toString.call(points) == "[object Array]"){
              for(let i=0;i<points.length;i++){
                if (!!points[i].latitude && !!points[i].longitude) {
                  var lng = Number(points[i].longitude);//经度
                  var lat = Number(points[i].latitude);//纬度

                  let point = new BMap.Point(lng,lat)
                
                 
                  // this.map2.setMapStyleV2({styleJson:eval(mapStyle.mapStyleBrown)});
                  var myIcon = new BMap.Icon(
                    this.pointSymbol,
                    new BMap.Size(40,40), {
                      imageSize: new BMap.Size(40, 40)
                    }
                  );
                  this.map.setMapType(BMAP_HYBRID_MAP);
                  //  this.map2.setMapType(BMAP_HYBRID_MAP);
                  // this.map.setHeading(64.5);
                  // this.map.setTilt(73);
                  // var marker = new BMap.Marker(point,{icon: myIcon});
                  // this.map.addOverlay(marker);
                  var that = this
                  var marker = new BMap.Marker(point,{icon: myIcon});
                 
                  this.map.addOverlay(marker);
                  var that = this
                  // marker.addEventListener("click",(e)=>{

                  //   vm.$router.push({
                  //       path: "/plant1page",
                  //       query: {
                  //         id: 13
                  //       }
                  //   })
                  // });
                  
                  marker.addEventListener("mouseover",(e)=>{
                    var opts = {
                        width : 110,     // 信息窗口宽度
                        height: 110,     // 信息窗口高度
                        title:points[i].fullname
                    }
                    // var sContent =
                    //   "<h4 style='margin:0 0 5px 0;padding:0.2em 0'></h4>"+`<div style='width: 138px;margin-left: 5px;float: left;'><div id='main' style='margin-bottom: 10px;'>${points[i].fullName}</div><div  style='margin-bottom: 6px;'>总设备<span style='margin-left: 30px;'>112</span></div>
                    //    <div  style='margin-bottom: 6px;'>同比上涨<span style='margin-left: 30px;'>58%</span></div>
                    //    <div  style='margin-bottom: 6px;'>总成本<span style='margin-left: 30px;'>58%</span></div>
                    //    <div  style='margin-bottom: 6px;'>总能耗<span style='margin-left: 30px;'>58%</span></div>
                    //    <div  style='margin-bottom: 6px;'>总碳排<span style='margin-left: 30px;'>58%</span></div>
                    //    <div  style='margin-bottom: 6px;'>总事件<span style='margin-left: 30px;'>58%</span></div>
                    //    <div  style='margin-bottom: 6px;'>总工单<span style='margin-left: 30px;'>58%</span></div>
                    //    </div>`
                    var sContent =
                      `<div style='width: 250px;margin-left: 5px;float: left;padding: 0px 8px;margin-top: 26px;'>
                       <div style="display:flex;align-items: center;margin-bottom: 6px;"><div style="width: 10px;height: 10px;background: #2D76FE !important;border-radius: 0px;margin-right: 10px;"></div><div  style=''>设备总数<span style='margin-left: 20px;'>${this.detailInfo.sbCount !== undefined ? this.detailInfo.sbCount : ""}(台)</span></div>
                       </div>
                        <div style="display:flex;align-items: center;margin-bottom: 6px;"><div style="width: 10px;height: 10px;background: #4DB8FF !important;border-radius: 0px;margin-right: 10px;"></div><div  style=''>工单总数<span style='margin-left: 20px;'>${this.detailInfo.gdCount !== undefined ? this.detailInfo.gdCount : ""}(个)</span></div>
                        </div>
                        <div style="display:flex;align-items: center;margin-bottom: 6px;"><div style="width: 10px;height: 10px;background: #FFB200 !important;border-radius: 0px;margin-right: 10px;"></div><div  style=''>事件总数<span style='margin-left: 20px;'>0</span>(个)</div>
                        </div>
                       </div>`
                    
                    
                    var infoWindow = new BMap.InfoWindow(sContent,opts);  // 创建信息窗口对象
                    
                    that.map.openInfoWindow(infoWindow, point); //在point打的位置上进行一个标注
                    setTimeout(fun,1000);
                  }); //给每个点添加滑过事件
                  var size = new BMap.Size(-3,23);  // 创建信息窗口对象
                  function fun(){
                    // var myChart1 = echarts.init(document.getElementById('main'));
                    // console.log("如果找到id为main的div就会看到本条信息");
                    // let option = { //这个option是从echarts官网实例中搬过来的，自己的就不发了
                    // xAxis: {
                    // type: 'category',
                    // boundaryGap: false,
                    // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'] },
                    // yAxis: { type: 'value' },
                    // series: [{ data: [820, 932, 901, 934, 1290, 1330, 1320],
                    // type: 'line', areaStyle: {} }] }; myChart1.setOption(option);
                  }
                marker.setOffset(size); //开启信息窗口
                }
              }
            }else{
              
              if (!!points.latitude && !!points.longitude) {
                var lng = Number(points.longitude);//经度
                var lat = Number(points.latitude);//纬度
                var point = new BMap.Point(lng,lat)
                var myIcon = '';
                var marker = new BMap.Marker(point,{icon: myIcon});
                this.map.addOverlay(marker);
                var that = this;
                this.map.centerAndZoom(point, 6);
                this.map.setMapStyleV2({styleJson:eval(mapStyle.mapStyleBrown)});
                marker.addEventListener("mouseover",(e)=>{
                  this.overPoint(e,points)
                }); //给每个点添加滑过事件
                 marker.addEventListener("click",(e)=>{
                  console.log(vm)


                }); //给每个点添加滑过事件
                var size = new BMap.Size(50,50);  // 创建信息窗口对象

                marker.setOffset(size); //开启信息窗口
              }
            }
           
            // //这是一个点位
            // var markerPoint = new BMap.Point(111.404, 39.996);
            // //自定义marker---Size为图片大小，anchor为偏移量
            // var myIcon = new BMap.Icon("../img/logo-color.69af96b2.png", new BMap.Size(32, 32),{anchor:new BMap.Size(11, 32) });
            // // 创建Marker标注，使用图标
            // var marker = new BMap.Marker(markerPoint, {
            //     icon: myIcon
            // });
            // // 创建文本标注对象
            // var labelopts = {
            //     position: markerPoint, // 指定文本标注所在的地理位置
            //     offset: new BMap.Size(0, 0) // 设置文本偏移量
            // };
            // var label = new BMap.Label('12311111', labelopts);
            // //文本标注样式，transform为X轴平移，即文本居中显示
            // label.setStyle({
            //     color: "#fff",
            //     backgroundColor: "rgba(0, 0, 0, 0.5)",
            //     borderRadius: "10px",
            //     padding: "0 10px",
            //     fontSize: "14px",
            //     lineHeight: "20px",
            //     border :"0",
            //     transform:'translateX(-50%)'
            // });
            
            // //添加marker及文本标注
            // this.map.addOverlay(label);
            // this.map.addOverlay(marker);
            
    },
    clickbtn(index){
      
      this.maps = index
      if(this.maps){
        this.getData3()
      }else{
      
        this.getData2()
      }
      // console.log(this.map.setMapType)
      // this.map.setMapType(BMapGL_NORMAL_MAP);
      // // this.map.setHeading(64.5);
      // // this.map.setTilt(73);
      // console.log(this.map)

    },
    numberFormat(value) {
      let param = {}
      let k = 10000
      let sizes = ['', '万', '亿', '万亿']
      let i
      if (value < k) {
        param.value = value
        param.unit = ''
      } else {
        i = Math.floor(Math.log(value) / Math.log(k));
        param.value = ((value / Math.pow(k, i))).toFixed(2);
        param.unit = sizes[i];
      }
      return param;
    },
    getData() {
      let date = new Date()
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/master/site/info",
        method: "post",
        data: {
          // deptCode: this.currentSelectDeptInfo.CODE
          deptCode:"221027060"
        }
      }).then(res => {
        if(res){
          this.getMap(res.data.data["经纬度"])
          this.getMap2(res.data.data["经纬度"])
        }else{
          this.getMap([])
        }
        this.detailInfo.gdCount = res.data.data["工单总数"]
        this.detailInfo.sbCount = res.data.data["设备总数"]
        this.$axios({
          url: Config.dev_url + "/api-apps-v2/api/v2/energy/total",
          method: "post",
          data: {
            deptCode: this.currentSelectDeptInfo.CODE,
            equipment: this.currentSelectDeptInfo.CODE,
            queryType: "year",
            startTime: date.getFullYear() + '-01-01 00:00:00',
            endTime: date.getFullYear() + '-12-31 23:59:59',
            type: 0
          }
        }).then(res => {
          console.log(res)
          let result = res.data.data
          this.detailInfo.totalCount1 = result["当前最大功率数"]
          this.$axios({
            url: Config.dev_url + "/api-apps-v2/api/v2/electric/cost",
            method: "post",
            data: {
              deptCode: this.currentSelectDeptInfo.CODE,
              queryType: "year",
              startTime: date.getFullYear() + '-01-01 00:00:00',
              endTime: date.getFullYear() + '-12-31 23:59:59',
            }
          }).then(res => {
            this.detailInfo.totalCount2 = res.data.data.cost1
          })
        })
      })
    },
    getData2() {
      let date = new Date()
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/master/site/info",
        method: "post",
        data: {
          // deptCode: this.currentSelectDeptInfo.CODE
          deptCode:"221027060"
        }
      }).then(res => {
        if(res){
          this.getMap(res.data.data["经纬度"])
          
        }else{
          this.getMap([])
        }
        
      })
    },
    getData3() {
      let date = new Date()
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/master/site/info",
        method: "post",
        data: {
          // deptCode: this.currentSelectDeptInfo.CODE
          deptCode:"221027060"
        }
      }).then(res => {
        if(res){
          this.getMap3(res.data.data["经纬度"])
          
        }else{
          this.getMap([])
        }
        
      })
    },
    // //鼠标滑过地图上标记点显示信息
    overPoint(e, points) {
      var tips = {
        width: 250,     // 信息窗口宽度
        height: 100,     // 信息窗口高度
        title: "公司信息", // 信息窗口标题
        fontSize: "18px",
        enableMessage: true//设置允许信息窗发送短息
      };
      var evt = e.target;
      var point = new BMap.Point(evt.getPosition().lng, evt.getPosition().lat);
      var content = "<h4 style='margin:0 0 5px 0;padding:0.2em 0'></h4>" + "<div style='width: 208px;height:200px;margin-left: 5px;float: left;'><div id='main' style='margin-bottom: 10px;'>xxx公司</div><div  style='margin-bottom: 6px;'>设备总台数<span style='margin-left: 30px;'>112</span></div><div  style=''>同比上涨<span style='margin-left: 30px;'>58%</span></div></div>"
      var infoWindow = new BMap.InfoWindow(content, tips);  // 创建信息窗口对象
      this.map.openInfoWindow(infoWindow, point);
    },
  },
  mounted() {
    this.getData()
  }
}
</script>
      
<style lang="less" scoped>
.grouptitle {
  color: white;
  font-size: 0.2rem;

  &>span {
    color: white !important;
    font-size: 18px ;
    margin-right: 10px !important;
  }
}

.atlasMap {
  width: 100%;
  height: 100%;
  padding-bottom: 43px;
  padding-top: 14px;
}
#echartsMap{
  margin: 21px 0 0 0;
  height: calc(100% - 56px) !important
}
</style>
      