<template>
  <div class="graphleft">
    <siteName :objname="objname"></siteName>
    <div ref="CarbonOverview" id="CarbonAnalysis"></div>
  </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
import siteName from "../../financeView/site";
export default {
  name: 'CarbonOverview',
  props: {},
  components: { siteName },
  data() {
    return {
      searchObj: {
        VALUE: this.$moment(), //日期
        TYPE: "按年",
      },
      timetType: [
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      detailInfo: {
        chart1: {},
      },
      objname: {
        name: 'Carbon Emission Analysis',
        site: '碳排放分析'
      },
      colorList: [
        "#28d03a",
         "#f2e653",
        "#11a0ff",
        "#b33aff",
        "#696969",
        "#98FB98",
      ],

    }
  },
  watch: {
    "currentTime": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    "currenttype": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.searchObj.TYPE = "按年"
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    currentTime() {
      return this.$store.state.timer;
    },
    currenttype(){
      return this.$store.state.financetype;
    },
    currentSelectDeptInfo() {
      return this.$store.state.overviewdept;
    },
  },
  methods: {
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
    
      var obj = {
        按年: {
          // type: "year",
          starttime: VALUE.startOf("year").format("YYYY-MM"),
          endtime: VALUE.endOf("year").format("YYYY-MM"),
        },
      };
      return obj[TYPE];
    },
    getData() {
      this.isLoading = true;
      let url =
        Config.dev_url + "/api-apps-v2/api/v2/carbon_emissions/analysis";
        // Config.dev_url + "/api-apps/carbonManagement/getCarbonEmissionAnalysis";
      let data = {
        // code: "221027091",
        code: this.$store.state.overviewdept,
        // starttime: "2023-01",
        // endtime: "2023-12"
      };
      if (this.searchObj.TYPE == "按季") {
        Object.assign(data, this.getTimes(this.value));
      } else {
        Object.assign(data, this.transformSearch());
      }
      console.log(data)
      this.$axios
        .post(url, data)
        .then((res) => {
          console.log(res);
          this.detailInfo = {};
          var chart1 = {
            seriesData: [],
            indicator: [],
          };
          // let max = Math.max.apply(null, res.data.data.seriesData[0].value);
          let maxarr = [];
          for (let item of res.data.data.seriesData) {
            for (let item2 of item.value) {
              maxarr.push(item2);
            }
          }
          let max = Math.max.apply(null, maxarr);

          // let max = Math.max.apply(null, res.data.data.seriesData[0].value);
          if (res.data.data.categoryData !== []) {
            res.data.data.categoryData.forEach((item) => {
              // console.log(item);
              chart1.indicator.push({ text: item, max: max });
            });
          }
          // console.log(chart1.indicator);
          chart1.seriesData = res.data.data.seriesData;
          this.detailInfo.chart1 = chart1;
          this.initEchart();
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    initEchart() {
      var { indicator, seriesData } = this.detailInfo.chart1;
      console.log(indicator)
      console.log(seriesData)
      var dom = document.getElementById('CarbonAnalysis');
      let chartInit = echarts.init(dom);
      var option = option = {
        animation: true,
        layoutAnimation: false,
        legend: {
          orient: "vertical", //horizontal vertical
          icon: "rect", //图例形状
          top: "0%",
          right: 0,
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
          },
          // itemGap: 100,
        },
        tooltip: {
          // CO₂
          formatter: function (params) {
            let str = "";
            str += params.name + "<br>";
            // indicator.forEach(item=>{
            //     console.log(item);
            //     str += item.text + ":" + params.value[i]  + "<br>"
            // })
            for (let i = 0; i < indicator.length; i++) {
              str +=
                "<div style='width: 100%;display: flex;justify-content: space-between;align-items: center'>" +
                "<span>" +
                indicator[i].text +
                " : </span>" +
                "<span>" +
                params.value[i].toFixed(2) +
                "</span>" +
                "<span>" +
                " tCO₂" +
                "</span>" +
                "</div>";
            }
            return str;
          },
        },
        radar: {
          name: {
            textStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
            },
          },
          indicator: indicator,
          splitArea: {
            // 坐标轴在 grid 区域中的分隔区域，默认不显示。
            show: true,
          },
          center: ["50%", "60%"],
        },
        series: [
          {
            type: "radar",
            animationDurationUpdate: 2000,
            animationEasingUpdate: "quinticInOut",
            symbolSize: 6,
            symbol: "diamond",
            emphasis: {
              focus: "series",
            },
            data: seriesData,
          },
        ],
        color: this.colorList,
      };
      setTimeout(() => { chartInit.resize() }, 200)
      chartInit.setOption(option);
      window.addEventListener("resize", function () {
        chartInit.resize()
      });
    }

  },
  mounted() {
    this.getData()
  }
}
</script>
                
<style lang="less" scoped>
.graphleft {
  width: 100%;
  height: 100%;
  ul>li {
    width: 281px;
    height: 85px;
    display: flex;
    flex-direction: column;
    background-image: url(../../../../assets/icon/icon/lang01.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

#CarbonAnalysis {
  width: 100%;
  height: calc(100% - 38px);
}
</style>
                